// COMPONENTS
import React, { Component } from 'react';
import { Carousel, PropertyEvaluationButton } from '../../';

// CSS
import "./ClosedProperties.css";

class ClosedProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  openForm = () => {
    document.querySelector("form.property-evaluation-signup").classList.add("visible")
  };

  render() {
    return (
      <div className="closed-properties">
        <h3 className="closed-properties-header">NOTABLE CLOSED TRANSACTIONS</h3>
        <p className="line-one"/>
        <Carousel />
        {this.props.withButton ? <PropertyEvaluationButton /> : ""}
      </div>
    );
  };
};

export default ClosedProperties;