// COMPONENTS
import React, { Component } from "react";
import ItemsCarousel, { consts } from "react-elastic-carousel";
import { Link } from 'react-router-dom';
import { getClosedListings } from '../../../services/ApiService';

// IMAGES
import PreviousArrow from "../../../assets/images/multiple-pages/previous.png";
import NextArrow from "../../../assets/images/multiple-pages/next.png";

// CSS
import "./AdminClosedListings.css";

class AdminClosedListings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      closedListings: [],
      callCounter: 0
    };
    this.breakPoints = [
      { width: 1, itemsToShow: 2 },
      { width: 550, itemsToShow: 2, itemsToScroll: 1 },
      { width: 850, itemsToShow: 4, itemsToScroll: 4 }
    ]
  };

  componentDidMount = async () => {
    await this.fetchClosedListings();
  };

  fetchClosedListings = async () => {
    try {
      let closedListings = await getClosedListings();
      this.setState({ closedListings, callCounter: 0 });
      console.log(`GET ClosedListings successful. component-location: Carousel`);
    } catch (error) {
      if (this.state.callCounter >= 20) { return; };
      console.log(`GET ClosedListings UNsuccessful. component-location: Carousel`);
      this.state.callCounter++;
      this.setState({ canLoadMore: false });
      this.fetchClosedListings();
    }
  };

  myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? <img src={PreviousArrow} alt="previous" /> : <img src={NextArrow} alt="next" />
    return (
      <button className="admin-arrow-button" onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    )
  }

  renderCard = (listing, index) => {
    const { id, image, price, address, neighborhood, borough, square_feet, property_type } = listing;
    return (
      <Link to={`/admin/closed-properties/${id}`} key={index} className="admin-carousel-card">
        <div className="admin-card-image-container">
          <img className="admin-card-image-container-image" src={image} alt="property" />
          <p className="admin-card-price">{price}</p>
        </div>
        <div className="admin-card-content">
          <p className="admin-card-address">{address.toUpperCase()}</p>
          <p className="admin-card-town-and-city"><i className="fa fa-map-marker" aria-hidden="true"></i>{`${neighborhood}, ${borough}`}</p>
          <p className="admin-card-square-footage"><i className="fa fa-arrows-alt" aria-hidden="true"></i>{square_feet}</p>
          <p className="admin-card-address-summary">{property_type}</p>
        </div>
      </Link>
    );
  };

  render() {
    const { closedListings } = this.state;
    return (
      <div className="admin-closed-listings">
        <Link to="/admin/add/closed-properties">ADD +</Link>
        <ItemsCarousel
          breakPoints={this.breakPoints}
          itemsToShow={4}
          renderArrow={this.myArrow}
        >
          {closedListings.map((listing, index) => this.renderCard(listing, index))}
        </ItemsCarousel>
      </div>
    );
  };
};

export default AdminClosedListings;