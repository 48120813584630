// Components
import React, { Component } from "react";

// ASSETS
import IdentifyIcon from "../../../assets/images/1031-exchange/identify-1031-icon.png";
import WhatIsIcon from "../../../assets/images/1031-exchange/what-is-a-1031-exchange-icon.png"

// CSS
import "./TenThirtyOneExchangeInfo.css";

class TenThirtyOneExchangeInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {};
  };

  render() {
    return (
      <div className="ten-thirty-one-exchange-info">
        <div className="ten-thirty-one-exchange-info-section">
          <img src={WhatIsIcon} alt="what-is-icon" />
          <h3>WHAT IS A 1031 EXCHANGE?</h3>
          <div className="header-border-bottom" />
          <p>A 1031 exchange swaps one investment property for another to defer capital gains taxation. Put simply: it’s a way to save substantial sums of money when buying and selling commercial and investment properties.</p>
        </div>
        <div className="ten-thirty-one-exchange-info-section">
          <img src={IdentifyIcon} alt="identify-icon" />
          <h3>IDENTIFYING 1031 EXCHANGE</h3>
          <div className="header-border-bottom" />
          <p>We use our years of hands-on experience in the New York City markets to help you identify money-saving and profit-boosting 1031 exchange opportunities. With the help of our nationwide brokerage network and cutting-edge technology, we can help you identify exchange opportunities all over the United States.</p>
        </div>
      </div>
    );
  };
};

export default TenThirtyOneExchangeInfo;