// COMPONENTS
import React, { Component } from 'react';

// ASSETS
import BannerIcon from "../../../assets/images/multiple-pages/leasing-commercial-space.png";
import CompanyLogos from "../../../assets/images/leasing/leasing-logos.jpg";

// CSS
import "./LeasingBanner.css";

class LeasingBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="leasing-banner">
        <div className="leasing-banner-content">
          <img src={BannerIcon} alt="icon" />
          <h3>LET US FILL YOUR VACANCIES</h3>
          <p>We’ve been in the New York City market for years, and we know how to match the right tenants with the right properties. Let us use our expertise to source tenants for your latest investments.</p>
        </div>
        <img className="leasing-banner-image" src={CompanyLogos} alt="" />
      </div>
    );
  };
};

export default LeasingBanner;