// COMPONENTS
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  Header,
  CareersBanner,
  JobPositions,
  JobPosition,
  EmailSignup,
  Footer,
  PopUpForm
} from "../../components/";

// CSS
import "./Careers.css";

class Careers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  closeForm = (event) => {
    if (event.which === 27) {
      document.querySelector("form.pop-up-form").classList.remove("visible");
    };
  };

  render() {
    return (
      <div className="careers" onKeyDown={this.closeForm}>
        <Header />
        <CareersBanner />
        <Switch>
          <Route exact path="/careers/" render={() => <JobPositions handleChange={this.handleChange} />} />
          <Route exact path="/careers/:id" render={(props) => <JobPosition handleChange={this.handleChange} {...props} />} />
        </Switch>
        <EmailSignup handleChange={this.handleChange}/>
        <Footer />
        <PopUpForm email={this.state.email} />
      </div>
    );
  };
};

export default Careers;