// COMPONENTS
import React, { Component } from "react";

// CSS
import "./PropertyTypes.css"

class PropertyTypes extends Component {
  constructor(props) {
    super(props)
    this.state = {};
  };

  render() {
    return (
      <div className="property-types">
        <div className="property-type-container">
          <div className="property-type-background-image"/>
          <p className="property-type">Single-Tenant Net Lease <span>Properties</span></p>
        </div>
        <div className="property-type-container">
          <div className="property-type-background-image"/>
          <p className="property-type">Office Buildings</p>
        </div>
        <div className="property-type-container">
          <div className="property-type-background-image"/>
          <p className="property-type">New Developments</p>
        </div>
        <div className="property-type-container">
          <div className="property-type-background-image"/>
          <p className="property-type">Shopping Centers</p>
        </div>
      </div>
    );
  };
};

export default PropertyTypes;