// COMPONENTS
import React, { Component } from 'react';
import {
  Header,
  Banner,
  ClosedProperties,
  LearnMore,
  ContactUs,
  WeGiveBack,
  EmailSignup,
  Footer,
  PropertyEvaluationSignup,
  PopUpForm
} from "../../components";

// CSS
import "./HomePage.css";

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  closeForm = (event) => {
    if (event.which === 27) {
      document.querySelector("form.pop-up-form").classList.remove("visible");
      document.querySelector("form.property-evaluation-signup").classList.remove("visible");
    };
  };

  render() {
    return (
      <div className="homepage" onClick={this.closeForm} onKeyDown={this.closeForm}>
        <Header />
        <Banner />
        <ClosedProperties withButton={true} />
        <LearnMore />
        <ContactUs />
        <WeGiveBack />
        <EmailSignup handleChange={this.handleChange} />
        <Footer />
        <PropertyEvaluationSignup />
        <PopUpForm email={this.state.email} />
      </div>
    );
  };
};

export default Homepage;