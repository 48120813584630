// COMPONENTS
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import {
  Header,
  NewsAndInsightsPosts,
  NewsAndInsightsPost,
  SellPropertyForm,
  EmailSignup,
  Footer,
  PopUpForm
} from "../../components/";

// CSS
import "./NewsAndInsights.css";

class NewsAndInsights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  closeForm = (event) => {
    if (event.which === 27) {
      document.querySelector("form.pop-up-form").classList.remove("visible");
    };
  };

  render() {
    return (
      <div className="news-and-insights">
        <Header />
        <Switch>
          <Route exact path="/news-and-insights/" component={NewsAndInsightsPosts} />
          <Route exact path="/news-and-insights/:id" component={withRouter(NewsAndInsightsPost)} />
        </Switch>
        <SellPropertyForm backgroundColor="#414040" />
        <EmailSignup handleChange={this.handleChange} />
        <Footer />
        <PopUpForm email={this.state.email} />
      </div>
    );
  };
};

export default NewsAndInsights;