// COMPONENTS
import React, { Component } from "react";
import { amazonWebServicesImageUpload } from "../../../services/ApiService";

// CSS
import "./AdminEditForm.css";

class AdminEditForm extends Component {
  constructor(props) {
    super(props);
    //based on type of data, renders different models from backend
    if (props.type === "post") {
      this.state = {
        [props.type]: {
          image: "",
          title: "",
          text: "",
          author: ""
        },
        callCounter: 0,
        delete: "",
        errorMessage: ""
      };
    } else if (props.type === "listing") {
      this.state = {
        [props.type]: {
          priority: "",
          borough: "",
          type: "",
          asset_class: "",
          image: "",
          neighborhood: "",
          description: "",
          price: "",
          phone: "",
          broker: "",
          email: ""
        },
        callCounter: 0,
        delete: "",
        errorMessage: ""
      };
    } else if (props.type === "closed-listing") {
      this.state = {
        [props.type]: {
          priority: "",
          image: "",
          address: "",
          neighborhood: "",
          borough: "",
          price: "",
          square_feet: "",
          property_type: ""
        },
        callCounter: 0,
        delete: "",
        errorMessage: ""
      };
    } else if (props.type === "job") {
      this.state = {
        [props.type]: {
          priority: "",
          title: "",
          text: "",
        },
        callCounter: 0,
        delete: "",
        errorMessage: ""
      };
    }
  };

  componentDidMount = async () => {
    //need to fetch the previous data to have the default value in all input fields
    await this.fetchPreviousData();
  };

  fetchPreviousData = async () => {
    const id = this.props.match.params.id;
    //type is for state, and apiType for API calls
    const { type, apiType } = this.props;
    //require apiService for a dynamic call based on the apiType
    const apiService = require(`../../../services/ApiService`);
    try {
      const data = await apiService[`get${apiType}`](id);
      this.setState({ [type]: data, callCounter: 0 });
      console.log(`GET ${type}: ${id} successful. component-location: AdminEditForm?type=${type}`);
    } catch (error) {
      if (this.state.callCounter >= 20) { return; };
      console.log(`GET ${type}: ${id} UNsuccessful. component-location: AdminEditForm?type=${type}`);
      this.state.callCounter++;
      this.fetchPreviousData();
    };
  };

  //takes care of all changes in form
  handleChange = async (event) => {
    const { type } = this.props;
    const { name, value, files } = event.target;
    if (name === "image") {
      try {
        const image = await amazonWebServicesImageUpload(files);
        console.log(`POST AWS S3FileUpload successful. component-location: AdminEditForm?type=${type}`);
        this.setState(() => { return this.state[this.props.type][name] = image });
      } catch (error) {
        console.log(`POST AWS S3FileUpload UNsuccessful. component-location: AdminEditForm?type=${type}`, error);
      };
    } else if (name === "delete") {
      this.setState(() => { return this.state[name] = value });
    } else {
      this.setState(() => { return this.state[this.props.type][name] = value });
    };
  };

  //tries to update changes from the form, then redirects back to all items 
  submitChange = async (event) => {
    const id = this.props.match.params.id;
    const { type, apiType } = this.props;
    const apiService = require('../../../services/ApiService');
    try {
      await apiService[`update${apiType}`](id, { ...this.state[type] });
      this.setState({ callCounter: 0 });
      console.log(`PUT ${type}: ${id} successful. component-location: AdminEditForm?type=${type}`);
      this.props.history.push(`/admin/${type}s/`);
    } catch (error) {
      if (this.state.callCounter >= 20) { return; };
      console.log(`PUT ${type}: ${id} UNsuccessful. component-location: AdminEditForm?type=${type}`);
      this.state.callCounter++;
      this.submitChange(event);
    };
  };

  //open form for confirmation of deletion
  openDeleteForm = () => {
    this.setState({ deleteForm: true });
  };

  //checks if confirmation is accurate, then deletes and redirects back to all items
  confirmDeletion = async (event) => {
    const id = this.props.match.params.id;
    const { type, apiType } = this.props;
    const apiService = require(`../../../services/ApiService`);
    if (this.state.delete === "DELETE") {
      try {
        await apiService[`delete${apiType}`](id);
        console.log(`DELETE ${type}: ${id} successful. component-location: AdminEditForm?type=${type}`);
        this.props.history.push(`/admin/${type}s/`);
      } catch (error) {
        if (this.state.callCounter >= 20) { return; };
        console.log(`DELETE ${type}: ${id} UNsuccessful. component-location: AdminEditForm?type=${type}`);
        this.state.callCounter++;
        this.confirmDeletion(event);
      };
    } else {
      this.setState({ showError: true, errorMessage: "type 'DELETE' to confirm", delete: "" });
    };
  };

  renderForm = () => {
    const { type } = this.props;
    const { showError, errorMessage, deleteForm } = this.state;
    //set array variable for all model keys
    const objectKeys = Object.keys(this.state[type]);
    return (
      <>
        {showError && <h2 className="admin-edit-form-error-message">{errorMessage}</h2>}
        <form className="admin-edit-form-form" onChange={this.handleChange}>
          {objectKeys.map((key, index) => {
            //based on model key, have a different input type
            switch (key) {
              case "image": return (
                <div key={index}>
                  <label htmlFor={key}>{key} Size: Listings=411×299, Blog=973x575, Closed Listings=291×233</label>
                  <input name={key} type="file" required />
                  <img src={this.state[type][key]} alt="preview" />
                </div>
              );
              case "priority": return (
                <div key={index}>
                  <label htmlFor={key}>{key}:</label>
                  <input name={key} type="number" defaultValue={this.state[type][key]} required />
                </div>
              );
              case "type": return (
                <div key={index}>
                  <label htmlFor={key}>{key}:</label>
                  <select name={key} defaultValue={this.state[type][key]} required>
                    <option value="" selected={this.state[type][key] === "" && "selected"}>Type</option>
                    <option value="FOR SALE" selected={this.state[type][key] === "FOR SALE" && "selected"}>FOR SALE</option>
                    <option value="FOR LEASE" selected={this.state[type][key] === "FORE LEASE" && "selected"}>FOR LEASE</option>
                    <option value="JOINT VENTURE" selected={this.state[type][key] === "JOINT VENTURE" && "selected"}>JOINT VENTURE</option>
                    <option value="OTHER" selected={this.state[type][key] === "OTHER" && "selected"}>OTHER</option>
                  </select>
                </div>
              );
              case "borough": return (
                <div key={index}>
                  <label htmlFor={key}>{key}:</label>
                  <select name={key} required>
                    <option value="" selected={this.state[type][key] === "" && "selected"}>Borough</option>
                    <option value="Brooklyn" selected={this.state[type][key] === "Brooklyn" && "selected"}>Brooklyn</option>
                    <option value="Manhattan" selected={this.state[type][key] === "Manhattan" && "selected"}>Manhattan</option>
                    <option value="Queens" selected={this.state[type][key] === "Queens" && "selected"}>Queens</option>
                    <option value="Bronx" selected={this.state[type][key] === "Bronx" && "selected"}>Bronx</option>
                    <option value="StatenIsland" selected={this.state[type][key] === "StatenIsland" && "selected"}>StatenIsland</option>
                    <option value="Other" selected={this.state[type][key] === "Other" && "selected"}>Other</option>
                  </select>
                </div>
              );
              case "text": return (
                <div className="form-text" key={index}>
                  <label htmlFor={key}>
                    {key}:<br />
                    Paragraph = {`<p>text here</p>`},<br />
                    Link = {`<a href="url" target="_">Link Title</a>`}<br />
                    {type === "post" && `Blog Image = ${`<img>`}`}
                    {type === "job" && `Job Requirements = ${`<ul><li>1</li><li>2</li><li>3</li></ul>`}`}
                  </label>
                  <textarea name={key} defaultValue={this.state[type][key]} rows="25" required />
                </div>
              );
              case "description": return (
                <div key={index}>
                  <label htmlFor={key}>{key}: max = 110 characters</label>
                  <textarea name={key} defaultValue={this.state[type][key]} rows="7" required />
                </div>
              );
              //don't need a input field for id/timestamps
              case "id": return undefined;
              case "createdAt": return undefined;
              case "updatedAt": return undefined;
              default: return (
                <div key={index}>
                  <label htmlFor={key}>{key}:</label>
                  <input name={key} type="text" defaultValue={this.state[type][key]} required />
                </div>
              );
            };
          })}
        </form>
        <button onClick={this.submitChange}>Update</button>
        {
          deleteForm ?
            <>
              <form className="delete-form" onChange={this.handleChange}>
                <label htmlFor="delete">Type "DELETE" to Confirm Deletion:</label>
                <input name="delete" type="text" defaultValue={this.state.delete} required />
              </form>
              <button onClick={this.confirmDeletion}>DELETE</button>
            </>
            :
            <button onClick={this.openDeleteForm}>DELETE</button>
        }
      </>
    );
  };

  render() {
    return (
      <div className="admin-edit-form">
        {this.renderForm()}
      </div>
    );
  };
};

export default AdminEditForm;