// COMPONENTS
import React, { Component } from 'react';
import {
  Header,
  PropertyManagementBanner,
  WhyUs,
  PropertyManagementTech,
  AssetTypes,
  PropertyManagementServices,
  PropertyManagementContactUs,
  EmailSignup,
  Footer,
  PopUpForm
} from "../../components/";

// CSS
import "./PropertyManagement.css";

class PropertyManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  closeForm = (event) => {
    if (event.which === 27) {
      document.querySelector("form.pop-up-form").classList.remove("visible");
    };
  };

  render() {
    return (
      <div className="property-management">
        <Header />
        <PropertyManagementBanner />
        <WhyUs />
        <PropertyManagementTech />
        <AssetTypes investmentSales={false} />
        <PropertyManagementServices />
        <PropertyManagementContactUs />
        <EmailSignup handleChange={this.handleChange} />
        <Footer />
        <PopUpForm email={this.state.email} />
      </div>
    );
  };
};

export default PropertyManagement;