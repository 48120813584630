// COMPONENTS
import React, { Component } from 'react';

// CSS
import "./ContactUs.css";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="contact-us">
        <h2 className="contact-us-header">LEASING</h2>
        <h3 className="contact-us-content">Need to fill a vacancy? Let us find your next tenant.</h3>
        <a href="mailto:hgoldfein@goldcapre.com" target="_">CONTACT US</a>
      </div>
    );
  };
};

export default ContactUs;