// COMPONENTS
import React, { Component } from 'react';

// CSS
import "./PropertyEvaluationButton.css";

class PropertyEvaluationButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  openForm = () => {
    document.querySelector("form.property-evaluation-signup").classList.add("visible")
  };

  render() {
    return (
      <button className="closed-properties-button" onClick={this.openForm}>Get a FREE Property Evaluation</button>
    );
  };
};

export default PropertyEvaluationButton;