// COMPONENTS
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { getPosts } from '../../../services/ApiService';

// CSS
import "./AdminNewsAndInsights.css";

class AdminNewsAndInsights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      currentPage: 0,
      callCounter: 0,
      canLoadMore: true
    };
  };

  componentDidMount = async () => {
    await this.fetchPosts();
  };

  fetchPosts = async () => {
    try {
      let posts = await getPosts(this.state.currentPage, 6);
      this.setState({ posts, currentPage: this.state.currentPage + 1, callCounter: 0, canLoadMore: true });
      if (!this.state.posts.length) { this.setState({ canLoadMore: false }); };
      console.log(`GET Posts successful. component-location: AdminNewsAndInsightsPosts`);
    } catch (error) {
      if (this.state.callCounter >= 20) { return; };
      console.log(`GET Posts UNsuccessful. component-location: AdminNewsAndInsightsPosts`);
      this.state.callCounter++;
      this.setState({ canLoadMore: false });
      this.fetchPosts();
    };
  };

  loadMore = async () => {
    try {
      let newPosts = await getPosts(this.state.currentPage, 6);
      this.setState(prevState => {
        if (prevState.posts.length === [...prevState.posts, ...newPosts].length) return { canLoadMore: false };
        return { posts: [...prevState.posts, ...newPosts], currentPage: prevState.currentPage + 1, callCounter: 0 };
      });
      console.log(`GET (more) Posts successful. component-location: AdminNewsAndInsightsPosts`);
    } catch (error) {
      if (this.state.callCounter >= 20) { return; };
      console.log(`GET (more) Posts UNsuccessful. component-location: AdminNewsAndInsightsPosts`);
      this.state.callCounter++;
      this.loadMore();
    }
  };

  renderPosts = () => {
    return this.state.posts.map((post) => {
      const { id, image, title, text, createdAt, author } = post;
      const time = createdAt.replace('T', '-').split('-' || 'T');
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return (
        <div className="admin-news-and-insights-content-post" key={id}>
          <img src={image} alt="post" />
          <Link className="admin-news-and-insights-content-post-header" to={`/admin/news-and-insights/${id}`}>{title}</Link>
          <p className="admin-news-and-insights-content-post-text">{text.replace(/<p>|<\/p>/g, ' ').replace('<img>', ' ').substring(1, 133)}</p>
          <Link className="admin-edit-or-delete" to={`/admin/news-and-insights/${id}`}>Edit/Delete</Link>
          <div className="admin-news-and-insights-content-post-time-and-author">
            <p>{`${months[parseInt(time[1]) - 1]} ${time[2]}, ${time[0]}`}</p>
            <p className="bullet">&bull;</p>
            <p>by <span>{author}</span></p>
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div className="admin-news-and-insights">
        <div className="admin-news-and-insights-header">
          <h3 className="admin-news-and-insights-header-text">ADMIN NEWS & INSIGHTS <Link to="/admin/add/news-and-insights">ADD +</Link></h3>
        </div>
        <div className="admin-news-and-insights-content">
          <div>
            {this.renderPosts()}
          </div>
          <button className={this.state.canLoadMore ? "visible" : "hidden"} onClick={this.loadMore}>LOAD MORE ARTICLES</button>
        </div>
      </div>
    );
  };
};

export default AdminNewsAndInsights;