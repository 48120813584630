// COMPONENTS
import React, { Component } from 'react';

// ASSETS
import HonestyIcon from "../../../assets/images/investment-sales/honesty-icon.png";
import TransparencyIcon from "../../../assets/images/investment-sales/transparency-icon.png";

// CSS
import "./InvestmentSalesHonesty.css";

class InvestmentSalesHonesty extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="investment-sales-honesty">
          <div className="investment-sales-honesty-left">
            <img src={HonestyIcon} alt="honesty-and-integrity" />
            <h3>HONESTY & INTEGRITY</h3>
            <div />
            <p>The markets are treacherous, but your relationship with your broker shouldn’t be. At Goldcap, we believe that being able to trust your broker is essential to your investment success. That’s why ethical business practices are always our top priority, and why we only engage in honest negotiations.</p>
          </div>
          <div className="investment-sales-honesty-right">
            <img src={TransparencyIcon} alt="transparency" />
            <h3>TRANSPARENCY</h3>
            <div />
            <p>Your brokerage’s process shouldn’t be a mystery. At Goldcap, we give every client access to our deal-tracking software so you can see updates to your sale in real-time.</p>
          </div>
      </div>
    );
  };
};

export default InvestmentSalesHonesty;