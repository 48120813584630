// COMPONENTS
import React, { Component } from "react";

// ASSETS
import BannerIcon from "../../../assets/images/1031-exchange/1031-exchange-icon.png";
import McDonalds from "../../../assets/images/1031-exchange/mcdonalds.jpg";

// CSS
import "./TenThirtyOneExchangeBanner.css";

class TenThirtyOneExchangeBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {};
  };

  render() {
    return (
      <div className="ten-thirty-one-exchange-banner">
        <div className="ten-thirty-one-exchange-banner-content">
          <img src={BannerIcon} alt="icon"/>
          <h3>WE SPECIALIZE IN 1031 EXCHANGES</h3>
          <p>Let us maximize your profits by identifying and guiding you through 1031 exchange opportunities.</p>
        </div>
        <img className="ten-thirty-one-exchange-banner-image" src={McDonalds} alt="mcdonalds" />
      </div>
    );
  };
};

export default TenThirtyOneExchangeBanner;