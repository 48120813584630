// COMPONENTS
import React, { Component } from 'react';

// ASSETS

// CSS
import "./AdvisoryInfo.css";

class AdvisoryInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="advisory-info">
        <div className="advisory-info-image" />
        <div className="advisory-info-content">
          <div className="advisory-info-content-top">
            <h3>BROKER OPINION OF VALUE</h3>
            <div />
            <p>For sellers, our broker opinion of value provides a detailed analysis of all of the property’s features, its projected or current income and expenses, comparable sales, and the local market conditions. We use our years of on-the-ground experience to give you a realistic  price expectation for your property.</p>
          </div>
          <div className="advisory-info-content-bottom">
            <h3>COMPARATIVE MARKET ANALYSIS</h3>
            <div />
            <p>The Brooklyn market is constantly shifting. We couple our insider neighborhood expertise with cutting-edge technology to give you a birds-eye view of the market and where you fit into it.</p>
          </div>
        </div>
      </div>
    );
  };
};

export default AdvisoryInfo;