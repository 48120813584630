// COMPONENTS
import React, { Component } from "react";
import { createContact } from "../../../services/ApiService";
import { ClipLoader } from "react-spinners";

// ASSETS
import FormBackground from "../../../assets/images/1031-exchange/1031-form-background.jpg";

// CSS
import "./TenThirtyOneExchangeForm.css";

class TenThirtyOneExchangeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      request: {},
      error: false,
      errorMessage: "Something went wrong! try again later",
      callCounter: 0,
      loading: false,
      successful: false
    };
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState(() => { return this.state.request[name] = value });
  };

  submitChange = async (event) => {
    if (event) event.preventDefault();
    try {
      this.setState({ loading: true });
      await createContact({ ...this.state.request }, "1031-exchange");
      this.setState({ callCounter: 0, error: false, loading: false, successful: true });
      console.log(`POST contact successful. component-location: TenThirtyOneExchangeForm`);
    } catch (error) {
      if (this.state.callCounter >= 20) { this.setState({ error: true, loading: false }); return; };
      console.log(`POST contact UNsuccessful. component-location: TenThirtyOneExchangeForm`);
      this.state.callCounter++;
      this.submitChange();
    };
  };

  render() {
    const { request: { firstName, lastName, phone, email, message }, error, errorMessage, loading, successful } = this.state;
    return (
      <div className="ten-thirty-one-exchange-form">
        <p className="ten-thirty-one-exchange-form-text">A 1031 EXCHANGE IS TIME SENSITIVE <span>LET’S MAKE SURE YOU DON’T MISS OUT!</span></p>
        <form onChange={this.handleChange} onSubmit={this.submitChange}>
          {error && <p className="error-message">{errorMessage}</p>}
          <label htmlFor="firstName" className="ten-thirty-one-exchange-form-input-name">First Name<span>*</span></label>
          <label htmlFor="lastName" className="ten-thirty-one-exchange-form-input-name">Last Name<span>*</span></label>
          <input className="ten-thirty-one-exchange-form-input-name" type="text" name="firstName" defaultValue={firstName} required />
          <input className="ten-thirty-one-exchange-form-input-name" type="text" name="lastName" defaultValue={lastName} required />
          <label htmlFor="phone">Phone<span>*</span></label>
          <input type="tel" name="phone" defaultValue={phone} required />
          <label htmlFor="email">Email<span>*</span></label>
          <input type="email" name="email" defaultValue={email} required />
          <label htmlFor="message">Message</label>
          <textarea name="messageValue" defaultValue={message} />
          {
            successful ?
              <p className="ten-thirty-one-exchange-form-button">Thank You For Contacting Us</p>
              :
              <button>{loading ? <ClipLoader size={30} color={"#ffffff"} /> : 'CONTACT US!'}</button>
          }
        </form>
        <img className="ten-thirty-one-exchange-form-background" src={FormBackground} alt="" />
      </div>
    );
  };
};

export default TenThirtyOneExchangeForm;