import axios from 'axios';
import S3FileUpload from 'react-s3';

const JWT_TOKEN = document.cookie.replace(/(?:(?:^|.*;\s*)usr-acct\s*=\s*([^;]*).*$)|^.*$/, "$1");

export const apiCall = axios.create({
  baseURL: `https://www.goldcapre.com/`,
  // baseURL: `http://localhost:3001/`,
  headers: {
    'Authorization': `Bearer ${JWT_TOKEN}`
  }
});

// GOOGLE
export const createContact = async (data, group) => {
  try {
    const response = await apiCall.post(`google/people/create/${group}`, data);
    const { data: { contact } } = response;
    return contact;
  }
  catch (error) {
    throw error;
  };
};

// AWS
export const amazonWebServicesImageUpload = async (files) => {
  try {
    const response = await S3FileUpload.uploadFile(files[0], {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
    });
    const { location } = response;
    return location;
  }
  catch (error) {
    throw error;
  };
};

// USER
export const login = async (data) => {
  try {
    const response = await apiCall.post('auth/login', data, { withCredentials: true });
    const { data: { user } } = response;
    return user;
  }
  catch (error) {
    throw error;
  };
};

export const logout = async () => {
  try {
    const response = await apiCall.post('auth/logout', {}, { withCredentials: true });
    const { data: { message } } = response;
    return message;
  }
  catch (error) {
    throw error;
  };
};

export const signUp = async (data) => {
  try {
    await apiCall.post('auth/signup', data);
  }
  catch (error) {
    throw error;
  };
};

export const getProfile = async () => {
  try {
    const response = await apiCall.get('app/profile');
    const { data: { user } } = response;
    return user;
  }
  catch (error) {
    throw error;
  };
};

// JOB
export const getJobs = async () => {
  try {
    const response = await apiCall.get('jobs');
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const getJob = async (id) => {
  try {
    const response = await apiCall.get(`jobs/${id}`);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const createJob = async (body) => {
  try {
    const response = await apiCall.post(`jobs/`, body);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const updateJob = async (id, request) => {
  try {
    const response = await apiCall.put(`jobs/${id}`, request);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const deleteJob = async (id) => {
  try {
    const response = await apiCall.delete(`jobs/${id}`);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

// NEWS & INSIGHTS
export const getPosts = async (page, pageSize) => {
  try {
    const response = await apiCall.get(`posts?page=${page}&pageSize=${pageSize}`);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const getPost = async (id) => {
  try {
    const response = await apiCall.get(`posts/${id}`);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const createPost = async (body) => {
  try {
    const response = await apiCall.post(`posts/`, body);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const updatePost = async (id, request) => {
  try {
    const response = await apiCall.put(`posts/${id}`, request);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const deletePost = async (id) => {
  try {
    const response = await apiCall.delete(`posts/${id}`);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

// LISTINGS
export const getListings = async (borough, page, pageSize) => {
  try {
    const response = await apiCall.get(`listings/borough/${borough}?page=${page}&pageSize=${pageSize}`);
    const { data } = response;
    return data;
  } catch (error) {
    throw error;
  };
};

export const getListing = async (id) => {
  try {
    const response = await apiCall.get(`listings/id/${id}`);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const createListing = async (body) => {
  try {
    const response = await apiCall.post(`listings/`, body);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const updateListing = async (id, request) => {
  try {
    const response = await apiCall.put(`listings/${id}`, request);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const deleteListing = async (id) => {
  try {
    const response = await apiCall.delete(`listings/${id}`);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

// CLOSED LISTINGS
export const getClosedListings = async () => {
  try {
    const response = await apiCall.get(`closed-listings/`);
    const { data } = response;
    return data;
  } catch (error) {
    throw error;
  };
};

export const getClosedListing = async (id) => {
  try {
    const response = await apiCall.get(`closed-listings/${id}`);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const createClosedListing = async (body) => {
  try {
    const response = await apiCall.post(`closed-listings/`, body);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const updateClosedListing = async (id, request) => {
  try {
    const response = await apiCall.put(`closed-listings/${id}`, request);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};

export const deleteClosedListing = async (id) => {
  try {
    const response = await apiCall.delete(`closed-listings/${id}`);
    const { data } = response;
    return data;
  }
  catch (error) {
    throw error;
  };
};