// COMPONENTS
import React, { Component } from 'react';

// CSS
import "./AdvisoryServices.css";

class AdvisoryServices extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="advisory-services">
        <div className="advisory-services-left">
          <h3>QUARTERLY MARKET REPORTS</h3>
          <div />
          <p>We keep our eyes on the market so you don’t have to. The markets are constantly changing and every quarter brings new opportunities and challenges. Let us do the heavy lifting and give you what you need to know to stay competitive with our quarterly email newsletter.</p>
        </div>
        <div className="advisory-services-right">
          <h3>CAPITAL MARKETS</h3>
          <div />
          <p>We leverage our close relationships with New York City mortgage brokers to analyze existing debt liabilities and help you find the best refinancing options. For buyers, we also provide quotes for financing new acquisitions.</p>
        </div>
      </div>
    );
  };
};

export default AdvisoryServices;