// COMPONENTS
import React, { Component } from 'react';
import {
  Header,
  ListingsMain,
  SellPropertyForm,
  EmailSignup,
  Footer,
  PopUpForm
} from "../../components/";

// CSS
import "./Listings.css";

class Listings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  closeForm = (event) => {
    if (event.which === 27) {
      document.querySelector("form.pop-up-form").classList.remove("visible");
    };
  };

  render() {
    return (
      <div className="listings">
        <Header />
        <iframe src="https://cloud.creop.com/open/listing/NVHCUOEU" title="W3Schools Free Online Web Tutorials"></iframe>
        {/* <ListingsMain /> */}
        <SellPropertyForm backgroundColor="#414040" />
        <EmailSignup handleChange={this.handleChange} />
        <Footer />
        <PopUpForm email={this.state.email} />
      </div>
    );
  };
};

export default Listings;