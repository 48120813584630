// COMPONENTS
import React from 'react';
import { Route, Switch } from "react-router-dom";

// PAGES
import {
  Admin,
  Advisory,
  Careers,
  Homepage,
  InvestmentSales,
  Leasing,
  Listings,
  NewsAndInsights,
  PropertyManagement,
  TenThirtyOneExchange
} from "./pages/"

// CSS
import './App.css';

function App() {

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/advisory" component={Advisory} />
        <Route path="/careers" component={Careers} />
        <Route path="/investment-sales" component={InvestmentSales} />
        <Route path="/leasing" component={Leasing} />
        <Route path="/properties" component={Listings} />
        <Route path="/news-and-insights" component={NewsAndInsights} />
        <Route path="/property-management" component={PropertyManagement} />
        <Route path="/1031-exchange" component={TenThirtyOneExchange} />
        <Route path="/admin" component={Admin} />
      </Switch>
    </div>
  );
};

export default App;
