// COMPONENTS
import React, { Component } from 'react';

// ASSETS
import MultiFamily from "../../../assets/images/multiple-pages/asset-types-multifamily-icon.png";
import DevelopmentSite from "../../../assets/images/investment-sales/asset-types-development-site-icon.png";
import CondosAndCoOps from "../../../assets/images/property-management/condos.png";
import RetailAndCommercial from "../../../assets/images/multiple-pages/asset-types-retail-and-commercial-icon.png";

// CSS
import "./AssetTypes.css";

class AssetTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="asset-types">
        {
          this.props.investmentSales ?
            <h3 className="asset-types-header">ASSET TYPES WE SPECIALIZE IN</h3>
            :
            <h3 className="asset-types-header">PROPERTY TYPES</h3>
        }
        <div className="asset-types-header-underline" />
        <p className="asset-types-header-slogan">Modest to massive - we've got you covered</p>
        <div className="asset-types-content">
          <div className="asset-types-content-type">
            <img className="asset-types-content-image" src={MultiFamily} alt="multi-family" />
            <h4 className="asset-types-content-header">Multi-Family & Mixed Use</h4>
            {/* <p className="asset-types-content-description">Goldcap Property Management will work with the Board of Directors to ensure all objectives are met, preventative maintenance is scheduled, oversight of employees and any shareholder issues are tended to quickly.</p> */}
          </div>
          {
            this.props.investmentSales ?
              <div className="asset-types-content-type">
                <img className="asset-types-content-image" src={DevelopmentSite} alt="development-site" />
                <h4 className="asset-types-content-header">Development Sites & Raw Land</h4>
                {/* <p className="asset-types-content-description">Goldcap Property Management will work with the Board of Directors to ensure all objectives are met, preventative maintenance is scheduled, oversight of employees and any shareholder issues are tended to quickly.</p> */}
              </div>
              :
              <div className="asset-types-content-type">
                <img className="asset-types-content-image" src={CondosAndCoOps} alt="condos-and-co-ops" />
                <h4 className="asset-types-content-header">Condos & Co-ops</h4>
                {/* <p className="asset-types-content-description">Goldcap Property Management will work with the Board of Directors to ensure all objectives are met, preventative maintenance is scheduled, oversight of employees and any shareholder issues are tended to quickly.</p> */}
              </div>
          }
          <div className="asset-types-content-type">
            <img className="asset-types-content-image" src={RetailAndCommercial} alt="retail-and-commercial" />
            <h4 className="asset-types-content-header">Retail & Commercial</h4>
            {/* <p className="asset-types-content-description">Goldcap Property Management will work with the Board of Directors to ensure all objectives are met, preventative maintenance is scheduled, oversight of employees and any shareholder issues are tended to quickly.</p> */}
          </div>
        </div>
      </div>
    );
  };
};

export default AssetTypes;