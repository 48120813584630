// COMPONENTS
import React, { Component } from 'react';

// ASSETS
import LandlordRep from "../../../assets/images/leasing/landlord-rep.png";
import TenantRep from "../../../assets/images/leasing/tenant-rep.png";

// CSS
import "./LeasingInfo.css";

class LeasingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="leasing-info">
        <div className="leasing-info-image" />
        <div className="leasing-info-content">
          <div className="leasing-info-content-top">
            <img src={LandlordRep} alt="landlord" />
            <h3>LANDLORD REPRESENTATION</h3>
            <div />
            <p>We have years of experience identifying qualified tenants for investment properties. We’ll use our industry expertise and nationwide relationships to fill your vacancies.</p>
          </div>
          <div className="leasing-info-content-bottom">
            <img src={TenantRep} alt="tenant" />
            <h3>TENANT REPRESENTATION</h3>
            <div />
            <p>We’re New York City locals and we’re linked in to this market. We’ll leverage our list of local relationships to help you find the right space for your business.</p>
          </div>
        </div>
      </div>
    );
  };
};

export default LeasingInfo;