// COMPONENTS
import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";

// ASSETS
import Logo from "../../../assets/images/multiple-pages/GoldcapHeaderLogo.png";
import LogoMobile from "../../../assets/images/multiple-pages/GoldcapHeaderLogoMobile.png";
import Hamburger from "../../../assets/images/multiple-pages/goldcap-hamburger-menu.png";
import X from "../../../assets/images/multiple-pages/goldcap-x.png";

// CSS
import "./Header.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  toggleNav = () => {
    const navigation = document.querySelector('.header-links');
    navigation.classList.toggle('visible');
  };

  render() {
    return (
      <header>
        <div className="header-sign-up">
          <p>Join our exclusive list of off market deals! Take the survey to get customized deals just for you.</p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSeIFV1eeKrsYlflcBsSBRkbF0fUKrZgzp6tZIuTf8lTyTZSLA/viewform" target="_">SIGN UP</a>
        </div>
        <div className="header-main">
          <Link className="banner-logo-link" to="/">
            <img className="header-logo" src={Logo} alt="Logo" />
            <img className="header-logo-mobile" src={LogoMobile} alt="Logo" />
          </Link>
          <img className="header-banner-hamburger" src={Hamburger} alt="hamburger" onClick={this.toggleNav} />
          <div className="header-links">
            <div className="header-contact-us">
              <p>Contact Us:</p>
              <a href="tel:1-212-335-0708" target="_" className="header-contact-info header-phone">
                <i className="fas fa-phone-alt"></i>
                <p>(212)-335-0708</p>
              </a>
              <a href="mailto:hgoldfein@goldcapre.com" target="_" className="header-contact-info header-email">
                <i className="far fa-envelope"></i>
                <p>Email</p>
              </a>
              <a href="https://api.whatsapp.com/send?phone=12123350708" target="_" className="header-contact-info header-whatsapp">
                <i className="fab fa-whatsapp"></i>
                <p>WhatsApp</p>
              </a>
              {/* <a href="https://telegram.me/HeshieGoldfein" target="_" className="header-contact-info header-telegram">
                <i className="fab fa-telegram-plane"></i>
                <p>Telegram</p>
              </a> */}
            </div>
            <nav>
              <NavLink activeStyle={{ color: "#DE1F2C" }} to="/investment-sales">INVESTMENT SALES</NavLink>
              <NavLink activeStyle={{ color: "#DE1F2C" }} to="/1031-exchange">1031 EXCHANGE</NavLink>
              <NavLink activeStyle={{ color: "#DE1F2C" }} to="/leasing">LEASING</NavLink>
              <NavLink activeStyle={{ color: "#DE1F2C" }} to="/properties">LISTINGS</NavLink>
              <NavLink activeStyle={{ color: "#DE1F2C" }} to="/advisory">ADVISORY</NavLink>
              <NavLink activeStyle={{ color: "#DE1F2C" }} to="/property-management">PROPERTY MANAGEMENT</NavLink>
              <NavLink activeStyle={{ color: "#DE1F2C" }} to="/news-and-insights">NEWS & INSIGHTS</NavLink>
              <NavLink activeStyle={{ color: "#DE1F2C" }} to="/careers">CAREERS</NavLink>
            </nav>
            <img className="header-links-close-nav" src={X} alt="X" onClick={this.toggleNav} />
          </div>
        </div>
      </header>
    );
  };
};

export default Header;