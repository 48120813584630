import { getProfile, logout } from './ApiService';

export const isAuthenticated = async () => {
  try {
    await getProfile();
    return true;
  } catch (error) {
    console.error(error);
    return false;
  };
};

export const signOut = async () => {
  try {
    const message = await logout();
    return message;
  } catch (error) {
    return error;
  };
};
