// COMPONENTS
import React, { Component } from "react";
import { PropertyEvaluationButton } from "../../";

// CSS
import "./PropertyEvaluation.css";

class PropertyEvaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
    return (
      <div className="property-evaluation">
        <h3>LOOKING TO SELL YOUR PROPERTY?</h3>
        <PropertyEvaluationButton/>
      </div>
    );
  };
};

export default PropertyEvaluation;