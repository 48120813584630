// COMPONENTS
import React, { Component } from 'react';

// ASSETS
import BannerIcon from "../../../assets/images/advisory/advisory-banner-icon.png";
import BannerImage from "../../../assets/images/advisory/advisory-image.png";

// CSS
import "./AdvisoryBanner.css";

class AdvisoryBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="advisory-banner">
        <img className="advisory-banner-image" src={BannerImage} alt="background" />
        <div className="advisory-banner-content">
          <img src={BannerIcon} alt="icon" />
          <h3>BUYING OR SELLING? <span>LET US GUIDE YOU.</span></h3>
          <p>At Goldcap, we work with people first and buildings second. Out of all the transactions we facilitate every year, yours will always be the most important. We live in these markets and we’re here to guide you through them.</p>
          <a href="tel:1-212-335-0708" target="_">CALL NOW!</a>
        </div>
      </div>
    );
  };
};

export default AdvisoryBanner;