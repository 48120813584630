// COMPONENTS
import React, { Component } from 'react';
import { createContact } from "../../../services/ApiService";
import { ClipLoader } from "react-spinners";

// ASSETS
import BannerIcon from "../../../assets/images/property-management/property-management-trophy.png";

// CSS
import "./PropertyManagementBanner.css";

class PropertyManagementBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request: {},
      error: false,
      errorMessage: "Something went wrong! try again later",
      callCounter: 0,
      loading: false,
      successful: false
    };
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState(() => { return this.state.request[name] = value });
  };

  submitChange = async (event) => {
    if (event) event.preventDefault();
    try {
      this.setState({ loading: true });
      await createContact({ ...this.state.request }, "property-management");
      this.setState({ callCounter: 0, error: false, loading: false, successful: true });
      console.log(`POST contact successful. component-location: PropertyManagementBanner`);
    } catch (error) {
      if (this.state.callCounter >= 20) { this.setState({ error: true, loading: false }); return; };
      console.log(`POST contact UNsuccessful. component-location: PropertyManagementBanner`);
      this.state.callCounter++;
      this.submitChange();
    };
  };

  render() {
    const { request: { firstName, lastName }, error, errorMessage, loading, successful } = this.state;
    return (
      <div className="property-management-banner">
        <div className="property-management-banner-content">
          <img src={BannerIcon} alt="icon" />
          <h3>THE BEST IN PROPERTY MANAGEMENT</h3>
          <p>Our transparency, honesty, integrity and top-shelf customer service provide an unparalleled property management experience that you won’t find anywhere else.</p>
        </div>
        <form id="property-management-banner-form" onChange={this.handleChange} onSubmit={this.submitChange}>
          {error && <p className="error-message">{errorMessage}</p>}
          <h4>Request a consultation!</h4>
          <label htmlFor="firstName" className="property-management-banner-form-input-name">First Name<span>*</span></label>
          <label htmlFor="lastName" className="property-management-banner-form-input-name">Last Name<span>*</span></label>
          <input className="property-management-banner-form-input-name" type="text" name="firstName" defaultValue={firstName} required />
          <input className="property-management-banner-form-input-name" type="text" name="lastName" defaultValue={lastName} required />
          <label htmlFor="Phone">Phone<span>*</span></label>
          <input type="phone" name="phone" required />
          <label htmlFor="email">Email<span>*</span></label>
          <input type="email" name="email" required />
          {
            successful ?
              <p className="property-management-banner-form-button">Thank You For Contacting Us</p>
              :
              <button>{loading ? <ClipLoader size={30} color={"#ffffff"} /> : 'SUBMIT'}</button>
          }
        </form>
      </div>
    );
  };
};

export default PropertyManagementBanner;