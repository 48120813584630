// COMPONENTS
import React, { Component } from "react";
import { createContact } from '../../../services/ApiService';
import { ClipLoader } from 'react-spinners'

// ASSETS
import X from "../../../assets/images/multiple-pages/goldcap-x.png";

// CSS
import "./PropertyEvaluationSignup.css";

class PropertyEvaluationSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request: {},
      error: false,
      errorMessage: "Something went wrong! try again later",
      callCounter: 0,
      loading: false
    };
  };

  closeForm = () => {
    document.querySelector("form.property-evaluation-signup").classList.remove("visible");
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState(() => { return this.state.request[name] = value });
  };

  submitChange = async (event) => {
    if (event) event.preventDefault();
    try {
      this.setState({ loading: true });
      await createContact({ ...this.state.request }, "property-evaluation");
      this.setState({ callCounter: 0, error: false, loading: false });
      console.log(`POST contact successful. component-location: PropertyEvaluationSignup`);
      document.querySelector("form.property-evaluation-signup").classList.remove("visible");
    } catch (error) {
      if (this.state.callCounter >= 20) { this.setState({ error: true, loading: false }); return; };
      console.log(`POST contact UNsuccessful. component-location: PropertyEvaluationSignup`);
      this.state.callCounter++;
      this.submitChange();
    };
  };

  render() {
    const { request: { firstName, lastName, phone, address, city, state, zip }, error, errorMessage, loading } = this.state;
    return (
      <form className="property-evaluation-signup" onChange={this.handleChange} onSubmit={this.submitChange}>
        {error && <p className="error-message">{errorMessage}</p>}
        <img src={X} alt="X" onClick={this.closeForm} />
        <input placeholder="First Name" name="firstName" defaultValue={firstName} required />
        <input placeholder="Last Name" name="lastName" defaultValue={lastName} required />
        <input placeholder="Email" type="email" name="email" defaultValue={this.props.email} required />
        <input placeholder="Phone" type="tel" name="phone" defaultValue={phone} required />
        <input placeholder="Property Address" type="text" name="address" defaultValue={address} required />
        <input placeholder="City" type="text" name="city" defaultValue={city} required />
        <input placeholder="State" type="text" name="state" defaultValue={state} required />
        <input placeholder="Zip" type="text" name="zip" defaultValue={zip} required />
        <button>{loading ? <ClipLoader size={30} color={"#ffffff"} /> : 'Request Your Evaluation'}</button>
      </form>
    );
  };
};

export default PropertyEvaluationSignup;