// COMPONENTS
import React, { Component } from 'react';


// CSS
import "./CareersBanner.css";

class CareersBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="careers-banner">
        <h3>CAREERS AT GOLDCAP</h3>
        <p>We’re driven by passion, motivated by success, and grounded by integrity. Join a team of real estate experts who share your values.</p>
      </div>
    );
  };
};

export default CareersBanner;