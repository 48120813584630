// COMPONENTS
import React, { Component } from 'react';
import {
  Header,
  AdminMain,
  Footer
} from "../../components/";

// CSS
import "./Admin.css";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Header />
        <AdminMain />
        <Footer />
      </div>
    );
  };
};

export default Admin;