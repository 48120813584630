// COMPONENTS
import React, { Component } from 'react';

// ASSETS
// import Building from "../../../assets/images/investment-sales/investment-sales-info-building.jpg";
import DealSourcingIcon from "../../../assets/images/multiple-pages/deal-sourcing-icon.png";
import MoneyIcon from "../../../assets/images/multiple-pages/money-icon.png";

// CSS
import "./InvestmentSalesInfo.css";

class InvestmentSalesInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="investment-sales-info">
        <div className="investment-sales-info-content">
          <div className="investment-sales-info-content-top">
            <img src={DealSourcingIcon} alt="" />
            <h3>DEAL SOURCING</h3>
            <div />
            <p>We bring together years of hands-on experience in the New York City commercial real estate market with the latest technologies to help you find and close deals at the best possible price. Our industry connections and domain expertise ensure steady deal flow and investment opportunities that are second to none.</p>
          </div>
          <div className="investment-sales-info-content-bottom">
            <img src={MoneyIcon} alt="" />
            <h3>SELLING IT!</h3>
            <div />
            <p>We live and breathe the New York City markets. Let us use our insider expertise to identify hidden strengths and weaknesses, conduct an in-depth broker opinion of value and position your property for optimal success.</p>
          </div>
        </div>
        <div className="investment-sales-info-image" />
      </div>
    );
  };
};

export default InvestmentSalesInfo;