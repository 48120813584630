// COMPONENTS
import React, { Component } from 'react';

// ASSETS
import Computer from "../../../assets/images/investment-sales/tech-forward-image.jpg"
import TechIcon from "../../../assets/images/investment-sales/tech-icon.png";

// CSS
import "./InvestmentSalesTech.css";

class InvestmentSalesTech extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="investment-sales-tech">
        <div className="investment-sales-tech-content">
          <img src={TechIcon} alt="" />
          <h3>TECH FORWARD</h3>
          <div />
          <p>Markets evolve, and you need a brokerage that evolves with them. We stay up to date with the latest in CRE technology to ensure that we’re always using the best tools to streamline your experience.</p>
        </div>
        <img className="investment-sales-tech-image" src={Computer} alt="tech" />
      </div>
    );
  };
};

export default InvestmentSalesTech;