// COMPONENTS
import React, { Component } from 'react';

// ASSETS
import LegalCompliance from "../../../assets/images/property-management/legal-compliance.png";
import Emergency from "../../../assets/images/property-management/24-7-emergency.png";
import RentCollection from "../../../assets/images/property-management/rent-collection.png";
import Cloud from "../../../assets/images/property-management/cloud-based-maintenance.png";
import FinancialAccess from "../../../assets/images/property-management/access-to-reports.png";
import FrequentPropertyInspections from "../../../assets/images/property-management/frequent-property-inspections.png";
import Maintenance from "../../../assets/images/property-management/general-and-preventative-maintenance.png";
import Cleaning from "../../../assets/images/property-management/sanitation-and-cleaning-common-areas.png";
import MoveInInspections from "../../../assets/images/property-management/move-in-out-inspections.png";
import ApartmentRentals from "../../../assets/images/property-management/apartment-rentals.png";
import TenantScreening from "../../../assets/images/property-management/tenant-screening.png";
import PropertyValueReports from "../../../assets/images/property-management/property-value-reports.png";
import SalesOfSpace from "../../../assets/images/property-management/sales-of-commercial-space.png";
import LeasingSpace from "../../../assets/images/property-management/leasing-commercial-space.png";


// CSS
import "./PropertyManagementServices.css";

class PropertyManagementServices extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="property-management-services">
        <div className="property-management-services-header">
          <h3 className="property-management-services-header-text">OUR SERVICES</h3>
          <div className="property-management-services-header-underline" />
        </div>
        <div className="property-management-services-row-content desktop-odd responsive-odd">
          <img className="property-management-services-row-content-image" src={LegalCompliance} alt="legal-compliance" />
          <h4 className="property-management-services-row-content-header">Legal Compliance</h4>
          <p className="property-management-services-row-content-description">Our legal advisors ensure you stay on the right side of the law and avoid major compliance headaches.</p>
        </div>
        <div className="property-management-services-row-content desktop-odd responsive-odd">
          <img className="property-management-services-row-content-image" src={Emergency} alt="24-7-emergency" />
          <h4 className="property-management-services-row-content-header">24/7 Emergency Response</h4>
          <p className="property-management-services-row-content-description">Weekend leak? No problem. We offer 24/7 emergency response so you’re never hung out to dry.</p>
        </div>
        <div className="property-management-services-row-content desktop-odd responsive-even">
          <img className="property-management-services-row-content-image" src={RentCollection} alt="rent-collection" />
          <h4 className="property-management-services-row-content-header">Rent Collection</h4>
          <p className="property-management-services-row-content-description">Leave the rent collection to us so you can spend more time running your business.</p>
        </div>
        <div className="property-management-services-row-content desktop-even responsive-even">
          <img className="property-management-services-row-content-image" src={Cloud} alt="cloud-based-tenant-maintenance-requests" />
          <h4 className="property-management-services-row-content-header">Cloud Based Tenant Maintenance Requests</h4>
          <p className="property-management-services-row-content-description">Streamline your maintenance operations with our cloud-based tenant request systems.</p>
        </div>
        <div className="property-management-services-row-content desktop-even responsive-odd">
          <img className="property-management-services-row-content-image" src={FinancialAccess} alt="24-7-access-to-financial-and-maintenance-reports" />
          <h4 className="property-management-services-row-content-header">24/7 Access to Financial & Maintenance Reports</h4>
          <p className="property-management-services-row-content-description">Transparency matters. With 24/7 access to financial and maintenance reports, you’ll never be left in the dark when it comes to your investment.</p>
        </div>
        <div className="property-management-services-row-content desktop-even responsive-odd">
          <img className="property-management-services-row-content-image" src={FrequentPropertyInspections} alt="frequent-property-inspections" />
          <h4 className="property-management-services-row-content-header">Frequent Property Inspections</h4>
          <p className="property-management-services-row-content-description">We keep tenants happy and prevent issues from turning into problems with frequent property inspections.</p>
        </div>
        <div className="property-management-services-row-content desktop-odd responsive-even">
          <img className="property-management-services-row-content-image" src={Maintenance} alt="general-and-preventative-maintenance" />
          <h4 className="property-management-services-row-content-header">General & Preventative Maintenance</h4>
          <p className="property-management-services-row-content-description">Our maintenance professionals keep your investment safe with regular upkeep.</p>
        </div>
        <div className="property-management-services-row-content desktop-odd responsive-even">
          <img className="property-management-services-row-content-image" src={Cleaning} alt="sanitation-and-cleaning-common-areas" />
          <h4 className="property-management-services-row-content-header">Sanitation & Cleaning in Common Areas</h4>
          <p className="property-management-services-row-content-description">We keep your tenants safe and healthy with regular cleaning and sanitation in common areas.</p>
        </div>
        <div className="property-management-services-row-content desktop-odd responsive-odd">
          <img className="property-management-services-row-content-image" src={MoveInInspections} alt="move-in-or-out-inspections" />
          <h4 className="property-management-services-row-content-header">Move-In / Out Inspections</h4>
          <p className="property-management-services-row-content-description">Make sure your tenants leave your property just the way they found it.</p>
        </div>
        <div className="property-management-services-row-content responsive-odd">
          <div className="property-management-services-row-content-image" />
          <h4 className="property-management-services-row-content-header"> </h4>
          <p className="property-management-services-row-content-description"> </p>
        </div>
        <div className="property-management-services-header">
          <h3 className="property-management-services-header-text">REAL ESTATE SERVICES</h3>
          <div className="property-management-services-header-underline" />
        </div>
        <div className="property-management-services-row-content desktop-odd responsive-odd">
          <img className="property-management-services-row-content-image" src={ApartmentRentals} alt="apartment-rentals" />
          <h4 className="property-management-services-row-content-header">Apartment Rentals</h4>
          <p className="property-management-services-row-content-description">We know what New Yorkers want. We use our hands-on, local knowledge to find you tenants fast.</p>
        </div>
        <div className="property-management-services-row-content desktop-odd responsive-odd">
          <img className="property-management-services-row-content-image" src={TenantScreening} alt="tenant-screening" />
          <h4 className="property-management-services-row-content-header">Tenant Screening</h4>
          <p className="property-management-services-row-content-description">Let us screen your tenants so that your latest investment doesn’t turn into your latest disaster.</p>
        </div>
        <div className="property-management-services-row-content desktop-odd responsive-even">
          <img className="property-management-services-row-content-image" src={PropertyValueReports} alt="property-value-reports" />
          <h4 className="property-management-services-row-content-header">Property Value Reports</h4>
          <p className="property-management-services-row-content-description">Keep your finger on your property’s pulse with our quarterly property value reports.</p>
        </div>
        <div className="property-management-services-row-content desktop-even responsive-even">
          <img className="property-management-services-row-content-image" src={SalesOfSpace} alt="commercial-space-sales" />
          <h4 className="property-management-services-row-content-header">Commercial Sales</h4>
          <p className="property-management-services-row-content-description">We know what sells. Let us use our years of experience to guide you through your next sale.</p>
        </div>
        <div className="property-management-services-row-content desktop-even responsive-odd">
          <img className="property-management-services-row-content-image" src={LeasingSpace} alt="commercial-space-leasing" />
          <h4 className="property-management-services-row-content-header">Commercial Leasing</h4>
          <p className="property-management-services-row-content-description">We’ll help you fill your vacancies so you can reduce your turnover time and maximize your ROI.</p>
        </div>
        <div className="property-management-services-row-content desktop-even responsive-odd">
          <div className="property-management-services-row-content-image" />
          <h4 className="property-management-services-row-content-header"> </h4>
          <p className="property-management-services-row-content-description"> </p>
        </div>
      </div>
    );
  };
};

export default PropertyManagementServices;