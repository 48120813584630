// COMPONENTS
import React, { Component } from "react";

// ASSETS
import buildings from "../../../assets/images/multiple-pages/email-sign-up-buildings.jpg";

// CSS
import "./EmailSignUp.css";

class EmailSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  openForm = (event) => {
    event.preventDefault();
    document.querySelector("form.pop-up-form").classList.add("visible");
  };

  render() {
    return (
      <div className="email-sign-up">
        <img src={buildings} alt="" />
        <div className="email-sign-up-content">
          <h2 className="email-sign-up-header">SIGN UP AND STAY INFORMED!</h2>
          <p className="email-sign-up-content-text">Join our newsletter and get updates on new listings, investor insights and more delivered straight to your inbox.</p>
          <form className="email-sign-up-form" onSubmit={this.openForm} onChange={this.props.handleChange}>
            <input placeholder="Email address..." type="email" name="email" required />
            <button>JOIN NOW!</button>
          </form>
        </div>
      </div>
    );
  };
};

export default EmailSignup;