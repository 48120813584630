// COMPONENTS
import React, { Component } from 'react';

// CSS
import "./WhyUs.css";

class WhyUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="why-us">
        <div className="why-us-image" />
        <div className="why-us-content">
          <h3 className="why-us-content-header">WHY CHOOSE US</h3>
          <div className="why-us-content-header-underline"/>
          <p className="why-us-content-text">We hold ourselves to high standards and use our industry expertise to cut operating costs, reduce tenant turnover, increase your ROI and create an unparalleled investment experience for our clients.</p>
        </div>
      </div>
    );
  };
};

export default WhyUs;