// COMPONENTS
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getJobs } from '../../../services/ApiService.js';

// CSS
import "./JobPositions.css";

class JobPositions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      callCounter: 0
    };
  };

  componentDidMount = async () => {
    await this.fetchJobs();
  };

  fetchJobs = async () => {
    try {
      let jobs = await getJobs();
      this.setState({ jobs, callCounter: 0 });
      console.log(`GET Jobs successful. component-location: JobPositions`);
    } catch (error) {
      if (this.state.callCounter >= 20) { return; };
      console.log(`GET Jobs UNsuccessful. component-location: JobPositions`);
      this.state.callCounter++;
      this.fetchJobs();
    };
  };

  openForm = (event) => {
    event.preventDefault();
    document.querySelector("form.pop-up-form").classList.add("visible");
  };

  renderJobs = () => {
    if (!this.state.jobs.length) { return <h1>SEND US YOUR RESUME</h1>; };
    return this.state.jobs.map((job) => {
      const { id, title, text } = job;
      return (
        <div className="job-positions-types-type" key={id}>
          <h4>{title.toUpperCase()}</h4>
          <p>{text.replace(/<p>|<\/p>|<\/br>|<b>|<\/b>/g, ' ').substring(1, 328) + "..."}</p>
          <Link to={`/careers/${id}`}>READ FULL DESCRIPTION &gt;</Link>
        </div>
      );
    });
  };

  render() {
    return (
      <div className="job-positions">
        <div className="job-positions-types">
          <h3 className="job-positions-types-header">Job Positions</h3>
          <div className="job-positions-types-header-underline" />
          {this.renderJobs()}
        </div>
        <div className="job-positions-sign-up">
          <h3>SIGN UP AND STAY INFORMED!</h3>
          <p>Join our newsletter and receive updates on listings, investor insights and more straight to your inbox</p>
          <form className="job-positions-sign-up-form" onChange={this.props.handleChange} onSubmit={this.openForm}>
            <input placeholder="Email address..." type="email" name="email" required />
            <button>SUBMIT</button>
          </form>
        </div>
      </div>
    );
  };
};

export default JobPositions;