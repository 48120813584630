// COMPONENTS
import React, { Component } from 'react';
import { createContact } from "../../../services/ApiService";
import { ClipLoader } from "react-spinners";

// ASSETS
import Computer from "../../../assets/images/advisory/advisory-form.jpg";

// CSS
import "./AdvisoryForm.css";

class AdvisoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request: {},
      error: false,
      errorMessage: "Something went wrong! try again later",
      callCounter: 0,
      loading: false,
      successful: false
    };
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState(() => { return this.state.request[name] = value });
  };

  submitChange = async (event) => {
    if (event) event.preventDefault();
    try {
      this.setState({ loading: true });
      await createContact({ ...this.state.request }, "sales-inquiry");
      this.setState({ callCounter: 0, error: false, loading: false, successful: true });
      console.log(`POST contact successful. component-location: AdvisoryForm`);
    } catch (error) {
      if (this.state.callCounter >= 20) { this.setState({ error: true, loading: false }); return; };
      console.log(`POST contact UNsuccessful. component-location: AdvisoryForm`);
      this.state.callCounter++;
      this.submitChange();
    };
  };

  render() {
    const { request: { firstName, lastName, phone, email, message }, error, errorMessage, loading, successful } = this.state;
    return (
      <div className="advisory-form">
        <div className="advisory-form-content">
          <h3 className="advisory-form-content-header">GET THE CLARITY YOU NEED AND <span>MAKE BETTER INFORMED DECISIONS TODAY!</span></h3>
          <div className="advisory-form-content-header-underline" />
          <form className="advisory-form-content-form" onChange={this.handleChange} onSubmit={this.submitChange}>
            {error && <p className="error-message">{errorMessage}</p>}
            <label htmlFor="firstName" className="advisory-form-content-form-input-name">First Name<span>*</span></label>
            <label htmlFor="lastName" className="advisory-form-content-form-input-name">Last Name<span>*</span></label>
            <input className="advisory-form-content-form-input-name" type="text" name="firstName" defaultValue={firstName} required />
            <input className="advisory-form-content-form-input-name" type="text" name="lastName" defaultValue={lastName} required />
            <label htmlFor="Phone">Phone<span>*</span></label>
            <input type="phone" name="phone" defaultValue={phone} required />
            <label htmlFor="email">Email<span>*</span></label>
            <input type="email" name="email" defaultValue={email} required />
            <label htmlFor="message">Message</label>
            <textarea className="msg-inpt" name="message" defaultValue={message} />
            {
              successful ?
                <p className="advisory-form-content-form-button">Thank You For Contacting Us</p>
                :
                <button>{loading ? <ClipLoader size={30} color={"#ffffff"} /> : 'CONTACT US!'}</button>
            }
          </form>
        </div>
        <div className="advisory-form-image" src={Computer} alt="" />
      </div>
    );
  };
};

export default AdvisoryForm;