// COMPONENTS
import React, { Component } from 'react';

// ASSETS
import Tablet from "../../../assets/images/property-management/constantly-investing-in-technology.jpg";

// CSS
import "./PropertyManagementTech.css";

class PropertyManagementTech extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
    return (
      <div className="property-management-tech">
        <div className="property-management-tech-content">
          <h3 className="property-management-tech-content-header">CONSTANTLY INVESTING IN TECHNOLOGY</h3>
          <div className="property-management-tech-content-header-underline" />
          <p className="property-management-tech-content-text">We’re always looking for new tech to make our services even better. When you work with us, you can view financial reports anytime, pay bills online, automatically adjust heating to reduce tenant complaints, detect leaks and monitor energy usage to optimize your ROI.</p>
        </div>
        <img className="property-management-tech-image" src={Tablet} alt="background" />
      </div>
    );
  };
};

export default PropertyManagementTech;