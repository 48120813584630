// COMPONENTS
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getPosts } from '../../../services/ApiService.js';

// CSS
import "./Footer.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      callCounter: 0
    };
  };

  componentDidMount = async () => {
    await this.fetchPosts();
  };

  fetchPosts = async () => {
    try {
      let posts = await getPosts(0, 4);
      this.setState({ posts, currentPage: this.state.currentPage += 1, callCounter: 0 });
      console.log(`GET Posts successful. component-location: Footer`);
    } catch (error) {
      if (this.state.callCounter >= 20) { return; };
      console.log(`GET Posts UNsuccessful. component-location: Footer`);
      this.state.callCounter++;
      this.setState({ canLoadMore: false });
      this.fetchPosts();
    };
  };

  goToTop = async () => {
    await window.scrollTo(0, 0);
  };

  renderPosts = () => {
    return this.state.posts.map((post) => {
      const { id, title, createdAt } = post;
      const time = createdAt.replace('T', '-').split('-' || 'T');
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return (
        <li className="footer-content-news-and-insights-list-item" key={id}>
          <Link className="footer-content-news-and-insights-list-item-text" to={`/news-and-insights/${id}`} onClick={this.goToTop}>{title}</Link>
          <p className="footer-content-news-and-insights-list-item-time">{`${months[parseInt(time[1]) - 1]} ${time[2]}, ${time[0]}`}</p>
        </li>
      );
    });
  };

  render() {
    return (
      <footer>
        <div className="footer-content">
          <div className="footer-content-column">
            <h3 className="footer-content-column-header">ABOUT US</h3>
            <ul className="footer-content-column-list">
              <li>Goldcap Real Estate Advisors is a full-service New York City commercial real estate brokerage focused on bringing honest negotiations and transparency to the Five Boroughs. With over ten years of experience in commercial real estate, Goldcap leverages its extensive industry expertise and long-standing relationships to provide top-shelf commercial real estate brokerage services.</li>
            </ul>
          </div>
          <div className="footer-content-column">
            <h3 className="footer-content-column-header">NEWS & INSIGHTS</h3>
            <ul className="footer-content-column-list">
              {this.renderPosts()}
            </ul>
          </div>
          <div className="footer-content-column">
            <h3 className="footer-content-column-header">GET IN TOUCH</h3>
            <ul className="footer-content-column-list">
              <li className="footer-get-in-touch-list-item">
                <h4 className="footer-get-in-touch-list-title">Address:</h4>
                <p className="footer-get-in-touch-list-address">266 47th Street</p>
                <p className="footer-get-in-touch-list-floor">Suite 369</p>
                <p className="footer-get-in-touch-list-city-zip">Brooklyn, NY 11220</p>
              </li>
              <li className="footer-get-in-touch-list-item">
                <h4 className="footer-get-in-touch-list-title">Office:</h4>
                <p className="footer-get-in-touch-list-content">(212)-335-0708</p>
              </li>
              <li className="footer-get-in-touch-list-item">
                <h4 className="footer-get-in-touch-list-title">Email:</h4>
                <p className="footer-get-in-touch-list-content">info@goldcapre.com</p>
              </li>
              <li className="footer-get-in-touch-list-item">
                <h4 className="footer-get-in-touch-list-title">Social:</h4>
                <div className="footer-get-in-touch-list-content">
                  <a href="https://www.linkedin.com/company/goldcap/" target="_"><i className="fa fa-linkedin" aria-hidden="true"></i> </a>
                  <a href="https://www.facebook.com/Goldcaprealestate/" target="_"><i className="fa fa-facebook" aria-hidden="true"></i> </a>
                  <a href="https://www.instagram.com/goldcaprealestate/" target="_"><i className="fa fa-instagram" aria-hidden="true"></i> </a>
                  <a href="https://twitter.com/GoldcapRE" target="_"><i className="fa fa-twitter" aria-hidden="true"></i> </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-copyright">&copy;Copyright Goldcap Real Estate Advisors 2024</div>
      </footer>
    );
  };
};

export default Footer;