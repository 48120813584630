// COMPONENTS
import React, { Component } from 'react';
import {
  Header,
  AdvisoryBanner,
  AdvisoryInfo,
  AdvisoryServices,
  AdvisoryForm,
  EmailSignup,
  Footer,
  PopUpForm
} from "../../components/";

// CSS
import "./Advisory.css";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  closeForm = (event) => {
    if (event.which === 27) {
      document.querySelector("form.pop-up-form").classList.remove("visible");
    };
  };

  render() {
    return (
      <div className="homepage">
        <Header />
        <AdvisoryBanner />
        <AdvisoryInfo />
        <AdvisoryServices />
        <AdvisoryForm />
        <EmailSignup handleChange={this.handleChange} />
        <Footer />
        <PopUpForm email={this.state.email} />
      </div>
    );
  };
};

export default HomePage;