// COMPONENTS
import React, { Component } from "react";
import { getListings } from "../../../services/ApiService";

// CSS
import "./ListingsMain.css";

class ListingsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boroughs: ["Brooklyn", "Manhattan", "Queens", "Bronx", "StatenIsland", "Other"],
      Brooklyn: [],
      Manhattan: [],
      Queens: [],
      Bronx: [],
      StatenIsland: [],
      Other: [],
      BrooklynCurrentPage: 0,
      ManhattanCurrentPage: 0,
      QueensCurrentPage: 0,
      BronxCurrentPage: 0,
      StatenIslandCurrentPage: 0,
      OtherCurrentPage: 0,
      callCounter: 0,
      BrooklynCanLoadMore: true,
      ManhattanCanLoadMore: true,
      QueensCanLoadMore: true,
      BronxCanLoadMore: true,
      StatenIslandCanLoadMore: true,
      OtherCanLoadMore: true
    };
  };

  componentDidMount = async () => {
    await this.fetchListings();
  };

  fetchListings = async () => {
    this.state.boroughs.map(async borough => {
      try {
        let listings = await getListings(borough, this.state[borough + 'CurrentPage'], 9);
        console.log(listings)
        this.setState({ [borough]: listings, currentPage: this.state.currentPage + 1, [borough + 'CurrentPage']: this.state[borough + 'CurrentPage'] += 1, callCounter: 0, [borough + 'CanLoadMore']: true });
        console.log(`GET Listings: ${borough} successful. component-location: ListingsMain`);
      } catch (error) {
        if (this.state.callCounter >= 20) { return; };
        console.log(`GET Listings: ${borough} UNsuccessful. component-location: ListingsMain`);
        this.state.callCounter++;
        this.fetchListings(borough);
      }
    });
  };

  loadMore = async (borough) => {
    try {
      let newListings = await getListings(borough, this.state[borough + 'CurrentPage'], 9);
      this.setState(prevState => {
        if (prevState[borough].length === [...prevState[borough], ...newListings].length) return { [borough + 'CanLoadMore']: false };
        return { [borough]: [...prevState[borough], ...newListings], [borough + 'CurrentPage']: prevState[borough + 'CurrentPage'] + 1, callCounter: 0 };
      });
      console.log(`GET (more) Listings successful. component-location: ListingsMain`);
    } catch (error) {
      if (this.state.callCounter >= 20) { return };
      console.log(`GET (more) Listings UNsuccessful. component-location: ListingsMain`);
      this.state.callCounter++;
      this.loadMore(borough);
    }
  };

  renderBoroughListings = (borough) => {
    return (
      <div className="listings-main-borough" key={borough}>
        {this.state[borough].length ?
          <>
            <h2>{borough.split(/(?=[A-Z])/).join(" ").toUpperCase()}</h2>
            <div className="listings-main-borough-header-underline" />
            <div className="listings-main-borough-listings">
              {this.state[borough].map(listing => {
                const { id, asset_class, image, neighborhood, type, description, price, broker, phone, email } = listing;
                return (
                  <div className="listings-main-borough-listings-listing" key={id}>
                    <h3>{asset_class.toUpperCase()}</h3>
                    <img src={image} alt="property" />
                    <h4>{neighborhood.toUpperCase()} - {type}</h4>
                    <p>{description}</p>
                    <p><span>Price: </span>{price}</p>
                    <p><span>Broker: </span>{broker}</p>
                    <p><span>Phone: </span>{phone}</p>
                    <p><span>Email: </span>{email}</p>
                  </div>
                );
              })}
            </div>
            <button className={this.state[borough + 'CanLoadMore'] ? "visible" : "hidden"} onClick={() => this.loadMore(borough)}>LOAD MORE</button>
          </>
          :
          ''
        }
      </div>
    );
  };

  render() {
    return (
      <div className="listings-main">
        {this.state.boroughs.map((borough) => this.renderBoroughListings(borough))}
      </div>
    );
  };
};

export default ListingsMain;