// COMPONENTS
import React, { Component } from "react";
import { NavLink, Route, Switch } from 'react-router-dom';
import { isAuthenticated, signOut } from '../../../services/AuthService';
import { login } from '../../../services/ApiService';
import {
  AdminNewsAndInsights,
  AdminClosedListings,
  AdminListings,
  AdminCareers,
  AdminAddForm,
  AdminEditForm
} from '../../';

// CSS
import "./AdminMain.css";

class AdminMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      callCounter: 0
    };
  };

  componentDidMount = async () => {
    await this.ifIsAuthenticated();
  };

  ifIsAuthenticated = async () => {
    try {
      const noNeedToAuthenticate = await isAuthenticated();
      this.setState({ noNeedToAuthenticate, callCounter: 0 });
      console.log(`POST Profile Validation successful. Profile Validated: ${noNeedToAuthenticate}. component-location: AdminMain`);
    } catch (error) {
      if (this.state.callCounter >= 5) { return; }
      this.state.callCounter++;
      console.log(`POST Profile Validation UNsuccessful. component-location: AdminMain`);
      this.ifIsAuthenticated()
    };
  };

  signOutUser = async () => {
    try {
      const message = await signOut();
      console.log(`POST Profile Logout successful. Logout Status: ${message}. component-location: AdminMain`);
    } catch (error) {
      console.log(`POST Profile Logout UNsuccessful. Logout Status: ${error.message}. component-location: AdminMain`);
    } finally {
      window.location.reload();
    };
  };

  handleSubmitForm = async evt => {
    evt.preventDefault();
    const { username, password } = this.state;
    try {
      await login({ username, password });
      this.setState({ noNeedToAuthenticate: true, showError: false });
    } catch (error) {
      this.setState(() => {
        if (error.request.status === 400) return { showError: true, password: '', errorMessage: 'Incorrect Username or Password' };
        else return { showError: true, password: '', errorMessage: 'Something Went Wrong, Try Again Later!' };
      });
    };
  };

  handleTextInput = async evt => {
    const { name, value } = evt.target;
    this.setState({
      [name]: value
    });
  };

  render() {
    const { noNeedToAuthenticate, showError, errorMessage } = this.state;
    return (
      <div className="admin-main">
        {noNeedToAuthenticate ?
          <div className="admin-signed-in">
            <nav>
              <NavLink activeStyle={{ color: "#DE1F2C" }} to="/admin/news-and-insights">NEWS & INSIGHTS</NavLink>
              <NavLink activeStyle={{ color: "#DE1F2C" }} to="/admin/closed-properties">NOTABLE CLOSED TRANSACTIONS</NavLink>
              <NavLink activeStyle={{ color: "#DE1F2C" }} to="/admin/properties">LISTINGS</NavLink>
              <NavLink activeStyle={{ color: "#DE1F2C" }} to="/admin/careers">CAREERS</NavLink>
              <button className="sign-out" onClick={this.signOutUser}>Sign Out</button>
            </nav>
            <Switch>
              <Route exact path="/admin/news-and-insights/" component={AdminNewsAndInsights} />
              <Route exact path="/admin/news-and-insights/:id" render={(props) => <AdminEditForm apiType="Post" type="post" {...props} />} />
              <Route exact path="/admin/add/news-and-insights/" render={(props) => <AdminAddForm apiType="Post" type="post" {...props} />} />
              <Route exact path="/admin/closed-properties/" component={AdminClosedListings} />
              <Route exact path="/admin/closed-properties/:id" render={(props) => <AdminEditForm apiType="ClosedListing" type="closed-listing" {...props} />} />
              <Route exact path="/admin/add/closed-properties/" render={(props) => <AdminAddForm apiType="ClosedListing" type="closed-listing" {...props} />} />
              <Route exact path="/admin/properties/" component={AdminListings} />
              <Route exact path="/admin/properties/:id" render={(props) => <AdminEditForm apiType="Listing" type="listing" {...props} />} />
              <Route exact path="/admin/add/properties/:borough" render={(props) => <AdminAddForm apiType="Listing" type="listing" {...props} />} />
              <Route exact path="/admin/careers/" component={AdminCareers} />
              <Route exact path="/admin/careers/:id" render={(props) => <AdminEditForm apiType="Job" type="job" {...props} />} />
              <Route exact path="/admin/add/careers/" render={(props) => <AdminAddForm apiType="Job" type="job" {...props} />} />
            </Switch>
          </div>
          :
          <form className="admin-login-form" onSubmit={this.handleSubmitForm}>
            {showError && <h2 className="admin-login-form-error">{errorMessage}</h2>}
            <div>
              <label htmlFor="username">Username</label>
              <input
                type="text"
                name="username"
                onChange={this.handleTextInput}
                value={this.state.username}
                required
              />
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                onChange={this.handleTextInput}
                value={this.state.password}
                required
              />
            </div>
            <button>Sign In</button>
          </form>
        }
      </div>
    );
  };
};

export default AdminMain;