// COMPONENTS
import React, { Component } from 'react';

// ASSETS
import ContactImage from "../../../assets/images/property-management/let-us-do-the-brainwork.jpg";

// CSS
import "./PropertyManagementContactUs.css";

class PropertyManagementContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="property-management-contact-us">
        <div className="property-management-contact-us-content">
          <h3 className="property-management-contact-us-content-header">PROPERTY MANAGEMENT IS A FULL-TIME JOB <span>WE TAKE CARE OF IT SO YOU CAN FOCUS ON NEW INVESTMENTS</span></h3>
          <div className="property-management-contact-us-content-header-underline" />
          <p className="property-management-contact-us-content-description">A great investor doesn’t always make a great property manager. We do the heavy lifting so you can spend your time tracking down new properties and optimizing your current investments.</p>
          <a href="#property-management-banner-form">CONTACT US</a>
        </div>
        <img className="property-management-contact-us-image" src={ContactImage} alt=""/>
      </div>
    );
  };
};

export default PropertyManagementContactUs;