// COMPONENTS
import React, { Component } from 'react';

// ASSETS
import BackgroundPattern from "../../../assets/images/multiple-pages/philanthropy-background.jpg";

// CSS
import "./WeGiveBack.css";

class WeGiveBack extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="we-give-back">
        <img src={BackgroundPattern} alt="background" />
        <div className="we-give-back-content">
          <h3>WE GIVE BACK</h3>
          <p>We believe that real estate should benefit the community. That’s why we give a portion of our proceeds to a charity of your choice.</p>
        </div>
      </div>
    );
  };
};

export default WeGiveBack;