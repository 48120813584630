// COMPONENTS
import React, { Component } from 'react';
import { Link } from "react-router-dom";

// ASSETS
import backgroundPattern from "../../../assets/images/multiple-pages/textured-background.jpg";

// CSS
import "./LearnMore.css";

class LearnMore extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="learn-more">
        <img src={backgroundPattern} alt="background" />
        <div className="learn-more-content">
          <h3>THE BEST IN COMMERCIAL REAL ESTATE BROKERAGE</h3>
          <p>In today’s competitive commercial real estate markets, good enough just won’t cut it. We provide top-shelf service, quality assurance and attention to detail for every client because we know your next deal shouldn’t settle for anything less than the best.</p>
          <Link to="/investment-sales">WHAT WE CAN DO FOR YOU</Link>
        </div>
      </div>
    );
  };
};

export default LearnMore;