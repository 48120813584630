// COMPONENTS
import React, { Component } from 'react';
import { getJob, createContact, amazonWebServicesImageUpload } from '../../../services/ApiService.js';
import { ClipLoader } from "react-spinners";

// CSS
import "./JobPosition.css";

class JobPosition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
      request: {},
      error: false,
      errorMessage: "Something went wrong! try again later",
      callCounter: 0,
      loading: false,
      successful: false
    };
  };

  componentDidMount = async () => {
    await this.fetchJob();
  };

  fetchJob = async () => {
    try {
      let job = await getJob(this.props.match.params.id);
      this.setState({ job, callCounter: 0 });
      console.log(`GET Job:${this.props.match.params.id} successful. component-location: JobPosition`);
    } catch (error) {
      if (this.state.callCounter >= 20) { return; };
      console.log(`GET Job:${this.props.match.params.id} UNsuccessful. component-location: JobPosition`);
      this.state.callCounter++;
      await this.fetchJob();
    };
  };

  openForm = (event) => {
    event.preventDefault();
    document.querySelector("form.pop-up-form").classList.add("visible");
  };

  handleChange = async (event) => {
    const { name, value, files } = event.target;
    if (name === "resume" || name === "coverLetter") {
      if (!files.length) { return; };
      const file = await amazonWebServicesImageUpload(files);
      this.setState(() => { return this.state.request[name] = file });
    } else {
      this.setState(() => { return this.state.request[name] = value });
    };
  };

  submitChange = async (event) => {
    if (event) event.preventDefault();
    try {
      this.setState({ loading: true });
      await createContact({ ...this.state.request }, "job-applicants");
      this.setState({ callCounter: 0, error: false, loading: false, successful: true });
      console.log(`POST contact successful. component-location: JobPosition`);
    } catch (error) {
      if (this.state.callCounter >= 20) { this.setState({ error: true, loading: false }); return; };
      console.log(`POST contact UNsuccessful. component-location: JobPosition`);
      this.state.callCounter++;
      this.submitChange();
    };
  };

  render() {
    const { job: { title, text }, request: { name, phone, email, resume, coverLetter }, error, errorMessage, loading, successful } = this.state;
    const dropDown = [];
    for (let i = 0; i <= 100; i++) {
      dropDown.push(i);
    };

    return (
      <div className="job-position">
        <div className="job-position-type">
          <h3 className="job-position-type-header">Job Position</h3>
          <div className="job-position-type-header-underline" />
          <h4>{title}</h4>
          <div className="job-description" dangerouslySetInnerHTML={{ __html: text }} />
          <form onChange={this.handleChange} onSubmit={this.submitChange}>
            {error && <p className="error-message">{errorMessage}</p>}
            <h4>Submit your resume and cover letter below</h4>
            <input className="left-input" placeholder="Name" name="name" type="text" defaultValue={name} required />
            <input className="right-input" placeholder="Email" name="email" type="email" defaultValue={email} required />
            <input className="left-input" placeholder="Phone" name="phone" defaultValue={phone} required />
            <select className="right-input" name="yearsOfExperience" required>
              <option value="">Years of Experience</option>
              {dropDown.map((number, index) => { return <option key={index} value={number}>{number}</option> })}
            </select>
            <input className="resume-file" name="resume" type="file" defaultValue={resume} required />
            <input className="resume-file" name="coverLetter" type="file" defaultValue={coverLetter} required />
            {
              successful ?
                <p>APPLICATION SENT</p>
                :
                <button >{loading ? <ClipLoader size={30} color={"#ffffff"} /> : 'SUBMIT'}</button>
            }
          </form>
        </div>
        <div className="job-position-sign-up">
          <h3>SIGN UP AND STAY INFORMED!</h3>
          <p>Join our newsletter and receive updates on listings, investor insights and more straight to your inbox</p>
          <form className="job-position-sign-up-form" onChange={this.props.handleChange} onSubmit={this.openForm}>
            <input placeholder="Email address..." required />
            <button>SUBMIT</button>
          </form>
        </div>
      </div>
    );
  };
};

export default JobPosition;