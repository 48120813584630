// COMPONENTS
import React, { Component } from "react";
import { amazonWebServicesImageUpload } from "../../../services/ApiService";

// CSS
import "./AdminAddForm.css";

class AdminAddForm extends Component {
  constructor(props) {
    super(props);
    //based on type of data, renders different models for the backend
    if (props.type === "post") {
      this.state = {
        [props.type]: {
          image: "",
          title: "",
          text: "",
          author: ""
        },
        callCounter: 0,
        errorMessage: ""
      };
    } else if (props.type === "listing") {
      this.state = {
        [props.type]: {
          priority: 0,
          borough: this.props.match.params.borough,
          type: "",
          asset_class: "",
          image: "",
          neighborhood: "",
          description: "",
          price: "",
          phone: "",
          broker: "",
          email: ""
        },
        callCounter: 0,
        errorMessage: ""
      };
    } else if (props.type === "closed-listing") {
      this.state = {
        [props.type]: {
          priority: 0,
          image: "",
          address: "",
          neighborhood: "",
          borough: "",
          price: "",
          square_feet: "",
          property_type: ""
        },
        callCounter: 0,
        errorMessage: ""
      };
    } else if (props.type === "job") {
      this.state = {
        [props.type]: {
          priority: 0,
          title: "",
          text: "",
        },
        callCounter: 0,
        errorMessage: ""
      };
    };
  };

  //takes care of all changes in form
  handleChange = async (event) => {
    const { type } = this.props;
    const { name, value, files } = event.target;
    if (name === "image") {
      try {
        const image = await amazonWebServicesImageUpload(files);
        console.log(`POST AWS S3FileUpload successful. component-location: AdminAddForm?type=${type}`);
        this.setState(() => { return this.state[this.props.type][name] = image });
      } catch (error) {
        console.log(`POST AWS S3FileUpload UNsuccessful. component-location: AdminAddForm?type=${type}`, error);
      };
    } else if (name === "delete") {
      this.setState(() => { return this.state[name] = value });
    } else {
      this.setState(() => { return this.state[this.props.type][name] = value });
    };
  };

  //tries to update changes from the form, then redirects back to all items 
  submitChange = async (event) => {
    event.preventDefault();
    const { type, apiType } = this.props;
    const apiService = require('../../../services/ApiService');
    try {
      await apiService[`create${apiType}`]({ ...this.state[type] });
      this.setState({ callCounter: 0 });
      console.log(`POST ${type} successful. component-location: AdminAddForm?type=${type}`);
      this.props.history.push(`/admin/${type}s/`);
    } catch (error) {
      if (this.state.callCounter >= 20) { this.setState({ showError: true, errorMessage: "something went wrong, please try again!" }); return; };
      console.log(`POST ${type} UNsuccessful. component-location: AdminAddForm?type=${type}`);
      this.state.callCounter++;
      this.submitChange(event);
    };
  };

  renderForm = () => {
    const { type } = this.props;
    const { showError, errorMessage } = this.state;
    //set array variable for all model keys
    const objectKeys = Object.keys(this.state[type]);
    return (
      <>
        {showError && <h2 className="admin-add-form-error-message">{errorMessage}</h2>}
        <form className="admin-add-form-form" onSubmit={this.submitChange} onChange={this.handleChange}>
          {objectKeys.map((key, index) => {
            //based on model key, have a different input type
            switch (key) {
              case "image": return (
                <div key={index}>
                  <label htmlFor={key}>{key} Size: Listings=411×299, Blog=973x575, Closed Listings=291×233</label>
                  <input name={key} type="file" required />
                  <img src={this.state[type][key]} alt="preview" />
                </div>
              );
              case "priority": return (
                <div key={index}>
                  <label htmlFor={key}>{key}:</label>
                  <input name={key} type="number" defaultValue={this.state[type][key]} required />
                </div>
              );
              case "type": return (
                <div key={index}>
                  <label htmlFor={key}>{key}:</label>
                  <select name={key} defaultValue={this.state[type][key]} required>
                  <option value="" selected={this.state[type][key] === "" && "selected"}>Type</option>
                    <option value="FOR SALE" selected={this.state[type][key] === "FOR SALE" && "selected"}>FOR SALE</option>
                    <option value="FOR LEASE" selected={this.state[type][key] === "FORE LEASE" && "selected"}>FOR LEASE</option>
                    <option value="JOINT VENTURE" selected={this.state[type][key] === "JOINT VENTURE" && "selected"}>JOINT VENTURE</option>
                    <option value="OTHER" selected={this.state[type][key] === "OTHER" && "selected"}>OTHER</option>
                  </select>
                </div>
              );
              case "borough": return (
                <div key={index}>
                  <label htmlFor={key}>{key}:</label>
                  <select name={key} required>
                    <option value="" selected={this.state[type][key] === "" && "selected"}>Borough</option>
                    <option value="Brooklyn" selected={this.state[type][key] === "Brooklyn" && "selected"}>Brooklyn</option>
                    <option value="Manhattan" selected={this.state[type][key] === "Manhattan" && "selected"}>Manhattan</option>
                    <option value="Queens" selected={this.state[type][key] === "Queens" && "selected"}>Queens</option>
                    <option value="Bronx" selected={this.state[type][key] === "Bronx" && "selected"}>Bronx</option>
                    <option value="StatenIsland" selected={this.state[type][key] === "StatenIsland" && "selected"}>StatenIsland</option>
                    <option value="Other" selected={this.state[type][key] === "Other" && "selected"}>Other</option>
                  </select>
                </div>
              );
              case "text": return (
                <div className="form-text" key={index}>
                  <label htmlFor={key}>
                    {key}:<br />
                    Paragraph = {`<p>text here</p>`},<br />
                    Link = {`<a href="url" target="_">Link Title</a>`}<br />
                    {type === "post" && `Blog Image = ${`<img>`}`}
                    {type === "job" && `Job Requirements = ${`<ul><li>1</li><li>2</li><li>3</li></ul>`}`}
                  </label>
                  <textarea name={key} defaultValue={this.state[type][key]} rows="25" required />
                </div>
              );
              case "description": return (
                <div key={index}>
                  <label htmlFor={key}>{key}:</label>
                  <textarea name={key} type="number" defaultValue={this.state[type][key]} rows="10" required />
                </div>
              );
              //don't need a input field for timestamps
              case "createdAt": return undefined;
              case "updatedAt": return undefined;
              default: return (
                <div key={index}>
                  <label htmlFor={key}>{key}:</label>
                  <input name={key} type="text" defaultValue={this.state[type][key]} required />
                </div>
              );
            };
          })}
          <button>Add</button>
        </form>
      </>
    );
  };

  render() {
    return (
      <div className="admin-add-form">
        {this.renderForm()}
      </div>
    );
  };
};

export default AdminAddForm;