 // COMPONENTS
import React, { Component } from 'react';

// ASSETS
import BannerIcon from "../../../assets/images/investment-sales/investment-sales-banner-icon.png";
import Building from "../../../assets/images/investment-sales/investment-sales-building.png";

// CSS
import "./InvestmentSalesBanner.css";

class InvestmentSalesBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="investment-sales-banner">
        <div className="investment-sales-banner-content">
          <img src={BannerIcon} alt="icon" />
          <h3>YOUR SUCCESS IS OUR MOST IMPORTANT INVESTMENT</h3>
          <p>At Goldcap, we work with people first, and buildings second. Nothing is more important to us than a happy client and a successful deal. That’s why our most important investment is in your success.</p>
        </div>
        <img className="investment-sales-banner-image" src={Building} alt="" />
      </div>
    );
  };
};

export default InvestmentSalesBanner;