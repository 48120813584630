// COMPONENTS
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { getPosts, getPost } from '../../../services/ApiService.js';

// CSS
import "./NewsAndInsightsPost.css";

class NewsAndInsightsPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {
        id: "",
        image: "",
        title: "",
        text: "",
        author: "",
        createdAt: ""
      },
      posts: [{
        id: "",
        image: "",
        title: "",
        text: "",
        author: "",
        createdAt: ""
      }],
      callCounter: 0,
      missingID: false,
    };
  };

  componentDidMount = async () => {
    await this.fetchPost();
    await this.fetchPosts();
    this.setState({ isLoading: true });
  };

  fetchPost = async () => {
    try {
      let post = await getPost(this.props.match.params.id);
      this.setState({ post, callCounter: 0 });
      console.log(`GET Post: ${this.props.match.params.id} successful. component-location: NewsAndInsightsPost`);
    } catch (error) {
      if (this.state.callCounter >= 20) {
        this.setState({ missingID: true });
        return;
      };
      console.log(`GET Post: ${this.props.match.params.id} successful. component-location: NewsAndInsightsPost`);
      this.state.callCounter++;
      this.fetchPost();
    };
  }

  fetchPosts = async () => {
    try {
      let posts = await getPosts(0, 4);
      this.setState({ posts, callCounter: 0 });
      console.log(`GET Posts successful. component-location: NewsAndInsightsPost`);
    } catch (error) {
      if (this.state.callCounter >= 20) { return };
      console.log(`GET Posts UNsuccessful. component-location: NewsAndInsightsPost`);
      this.state.callCounter++;
      this.fetchPosts();
    };
  };

  renderPosts = (months, posts) => {
    return posts.map((post) => {
      const { id, title, createdAt } = post;
      const time = createdAt.replace('T', '-').split('-' || 'T');
      return (
        <li key={id}>
          <Link to={`/news-and-insights/${id}`} onClick={this.changeId}>{title}</Link>
          <p className="news-and-insights-post-content-other-recent-articles-article-time">{`${months[parseInt(time[1]) - 1]} ${time[2]}, ${time[0]}`}</p>
        </li>
      );
    });
  };

  changeId = async (direction) => {
    if (direction === 'previous') {
      this.props.match.params.id = await Number(this.props.match.params.id) + 1;
      try {
        await getPost(this.props.match.params.id);
        this.props.history.push(`/news-and-insights/${this.props.match.params.id}`);
        window.location.reload();
        window.scrollTo(0, 0);
      } catch (error) {
        if (this.state.callCounter >= 100) { return; };
        this.state.callCounter++;
        this.changeId(direction);
      };
    } else if (direction === 'next') {
      this.props.match.params.id = await Number(this.props.match.params.id) - 1;
      try {
        await getPost(this.props.match.params.id);
        this.props.history.push(`/news-and-insights/${this.props.match.params.id}`);
        window.location.reload();
        window.scrollTo(0, 0);
      } catch (error) {
        if (this.state.callCounter >= 100) { return; };
        this.state.callCounter++;
        this.changeId(direction);
      };
    } else {
      await window.scrollTo(0, 0);
    };
  };

  render() {
    const { post: { id, image, title, text, author, createdAt }, posts } = this.state;
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const time = createdAt.replace('T', '-').split('-' || 'T');
    return (
      <div className="news-and-insights-post">
        <div className="news-and-insights-post-content">
          {
            this.state.missingID ?
              <div className="news-and-insights-post-content-current"><h3 className="news-and-insights-post-content-current-header">404 ERROR</h3></div>
              :
              <div className="news-and-insights-post-content-current">
                <h3 className="news-and-insights-post-content-current-header">{title}</h3>
                <div className="news-and-insights-post-content-current-time-and-author">
                  <p className="news-and-insights-post-content-current-time">{`${months[parseInt(time[1]) - 1]} ${time[2]}, ${time[0]}`}</p>
                  <p className="news-and-insights-post-content-current-bullet">&bull;</p>
                  <p className="news-and-insights-post-content-current-author">by <span>{author}</span></p>
                </div>
                <div className="news-and-insights-post-content-current-text" dangerouslySetInnerHTML={{ __html: text.replace('<img>', `<img src="${image}" alt="Post Image" />`) }} />
                <div className="news-and-insights-post-content-current-previous-next">
                  {posts[0].id === id ? <div /> : <p onClick={() => this.changeId('previous')} className="previous">{`< PREVIOUS ARTICLE`}</p>}
                  {id === 1 ? <div /> : <p onClick={() => this.changeId('next')} className="next">NEXT ARTICLE ></p>}
                </div>
              </div>
          }
          <div className="news-and-insights-post-content-other">
            <ul className="news-and-insights-post-content-other-recent-articles">
              <h4>RECENT ARTICLES</h4>
              {this.renderPosts(months, posts)}
            </ul>
            <div className="news-and-insights-post-content-other-signup">
              <h3>SIGN UP AND STAY INFORMED!</h3>
              <p>Join our newsletter and receive updates on listings, investor insights and more straight to your inbox</p>
              <form className="news-and-insights-post-content-other-signup-form">
                <input placeholder="Email address..." />
                <button>SUBMIT</button>
              </form>
            </div>
            <div className="news-and-insights-post-content-other-sharing-wrapper">
              <div className="news-and-insights-post-content-other-sharing-wrapper-sticky">
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${`https://www.goldcapre.com/news-and-insights/${id}`}&quote=${title}%0a${text.replace(/<p>|<\/p>/g, `%0a`).replace('<img>', ` `).replace(/<a.*>.*?<\/a>/ig, ` `)}`} target="_"><i className="fa fa-facebook" aria-hidden="true"></i> </a>
                <a href={`https://twitter.com/intent/tweet?url=${`https://www.goldcapre.com/news-and-insights/${id}`}&text=${title}`} target="_"><i className="fa fa-twitter" aria-hidden="true"></i> </a>
                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${`https://www.goldcapre.com/news-and-insights/${id}`}&title=${title}&summary=${text.replace(/<p>|<\/p>/g, ' ').replace('<img>', ' ').substring(1, 200)}&source=${`https://www.goldcapre.com/news-and-insights/${id}`}`} target="_"><i className="fa fa-linkedin" aria-hidden="true"></i> </a>
                <a href={`whatsapp://send?text=*${title}*%0a${text.replace(/<p>|<\/p>/g, `%0a`).replace('<img>', ` `).replace(/<a.*>.*?<\/a>/ig, ` `)}%20${`https://www.goldcapre.com/news-and-insights/${id}`}`} data-action="share/whatsapp/share" target="_"><i className="fa fa-whatsapp" aria-hidden="true"></i> </a>
                <a href={`mailto:?subject=${title}&body=${`${title}%0a${text.replace(/<p>|<\/p>/g, `%0a`).replace('<img>', ` `).replace(/<a.*>.*?<\/a>/ig, ` `)} %0ahttps://www.goldcapre.com/news-and-insights/${id}`}`} target="_"><i className="fa fa-envelope" aria-hidden="true"></i> </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default withRouter(NewsAndInsightsPost);