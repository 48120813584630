// COMPONENTS
import React, { Component } from 'react';
import {
  Header,
  LeasingBanner,
  LeasingInfo,
  LeasingForm,
  EmailSignup,
  Footer,
  PopUpForm
} from "../../components/";

// CSS
import "./Leasing.css";

class Leasing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  closeForm = (event) => {
    if (event.which === 27) {
      document.querySelector("form.pop-up-form").classList.remove("visible");
    };
  };

  render() {
    return (
      <div className="leasing">
        <Header />
        <LeasingBanner />
        <LeasingInfo />
        <LeasingForm />
        <EmailSignup handleChange={this.handleChange} />
        <Footer />
        <PopUpForm email={this.state.email} />
      </div>
    );
  };
};

export default Leasing;