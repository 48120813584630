// COMPONENTS
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { getJobs } from '../../../services/ApiService';

// CSS
import "./AdminCareers.css";

class AdminCareers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      callCounter: 0
    };
  };

  componentDidMount = async () => {
    await this.fetchJobs();
  };

  fetchJobs = async () => {
    try {
      let jobs = await getJobs();
      this.setState({ jobs, callCounter: 0 });
      console.log(`GET Jobs successful. component-location: AdminCareers`);
    } catch (error) {
      if (this.state.callCounter >= 20) { return; };
      console.log(`GET Jobs UNsuccessful. component-location: AdminCareers`);
      this.state.callCounter++;
      this.fetchJobs();
    };
  };

  renderJobs = () => {
    if (!this.state.jobs.length) { return <h1>EMPTY</h1>; };
    return this.state.jobs.map((job) => {
      const { id, title, text } = job;
      return (
        <div className="admin-job-positions-types-type" key={id}>
          <h4>{title.toUpperCase()}</h4>
          <p>{text.replace(/<p>|<\/p>/g, ' ').substring(1, 328)}</p>
          <Link to={`/admin/careers/${id}`}>EDIT/DELETE JOB POSITION ></Link>
        </div>
      );
    });
  };

  render() {
    return (
      <div className="admin-job-positions">
        <div className="admin-job-positions-types">
          <h3 className="admin-job-positions-types-header">Admin Job Positions <Link to="/admin/add/careers">ADD +</Link></h3>
          <div className="admin-job-positions-types-header-underline" />
          {this.renderJobs()}
        </div>
      </div>
    );
  };
};

export default AdminCareers;