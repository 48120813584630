// COMPONENTS
import React, { Component } from 'react';
import {Link} from 'react-router-dom';

// CSS
import "./Banner.css";

export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };
  
  render() {
    return (
      <div className="banner">
        <h1>BUILDING SUCCESS, BRICK BY BRICK.</h1>
        <p>We know that every deal is different and there isn’t one approach that always works. That’s why we tailor our services to your individual needs. We’re here to help you build your next success, step by step and brick by brick.</p>
        <Link to="/investment-sales">OUR SERVICES</Link>
      </div>
    );
  };
};